import type { TFunction } from 'next-i18next';
import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { SelectInput } from '@hotelplan/components.common.select-input';
import { FlightClass } from '@hotelplan/graphql.types';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import {
  FlightFormFieldTypes,
  useFlightFormContext,
} from 'components/domain/searchControl/useFlightFormContext';

const getFlightClassOptions = (
  t: TFunction
): Array<{ value: FlightClass; label: string }> => [
  {
    value: FlightClass.Economy,
    label: t('forms:economyFlightClass.selectInput'),
  },
  {
    value: FlightClass.PremiumEconomy,
    label: t('forms:premiumEconomyFlightClass.selectInput'),
  },
  {
    value: FlightClass.Business,
    label: t('forms:businessFlightClass.selectInput'),
  },
  {
    value: FlightClass.First,
    label: t('forms:firstFlightClass.selectInput'),
  },
];

const CabinClassInput = styled(SelectInput).attrs({
  testId: 'cabin-class',
  icon: { name: 'cabin' },
})<{ customLabel?: React.ReactNode }>(({ customLabel }) =>
  sx2CssThemeFn({
    '.input': {
      height: ['45px', '48px'],
    },
    '.status-icon': {
      top: Boolean(customLabel) && `calc(50% + 14px)`,
    },
  })
);

const FlightClassField: React.FC<{
  className?: string;
  customLabel?: React.ReactNode;
}> = ({ className, customLabel }) => {
  const [t] = useTranslation('forms');
  const { values, onFlightFormChange } = useFlightFormContext();

  // NOTE: Flight class should be the same for all partitions but it's kept on partition level that's why just get it
  // from the first partition and on change update all partitions.
  const flightClass = values?.flightPartitions
    ? values.flightPartitions[0].flightClass
    : FlightClass.Economy;

  return (
    <CabinClassInput
      tabIndex={0}
      hideLabel
      value={flightClass || FlightClass.Economy}
      options={getFlightClassOptions(t)}
      label={t('forms:flightClass.label')}
      className={className}
      onChange={(value): void => {
        onFlightFormChange(value, FlightFormFieldTypes.FlightClassField);
      }}
      customLabel={customLabel}
    />
  );
};

export default FlightClassField;
