import { useTranslation } from 'next-i18next';
import React, { Fragment, ReactNode } from 'react';
import styled from 'styled-components';
import { Image } from '@hotelplan/components.common.image';
import { Flight } from '@hotelplan/graphql.types';
import { mapFlightClass } from '@hotelplan/libs.mappers';
import { formatDate, parseDateStr } from '@hotelplan/libs.utils';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

const getStopsInfo = (
  flight: Flight | null | undefined
): { amount: number; label: string } => {
  const amount = flight ? flight.segments.length - 1 : 0;
  const stops = flight
    ? flight.segments.map(segment => segment.departure.iata)
    : [];

  return {
    amount,
    label: stops.slice(1).join('/'),
  };
};

const FLIGHT_DATE_FORMAT = 'd. MMM yyyy';

interface IFlightDetailsProps {
  testId?: string;
  flight: Flight | null | undefined;
}

const FlightDetailsWrapper = styled.div(
  sx2CssThemeFn({
    px: [3, '45px'],
    py: ['18px', '20px'],
  })
);

const DescriptionWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    mb: [2, 3],
    '.flight-route': {
      fontSize: [1, 3],
      color: 'secondary',
      letterSpacing: [null, '0.7px'],
      fontWeight: 'bold',
      marginRight: [4, 5],
    },
    '.flight-date': {
      fontSize: [1, 3],
      color: 'secondary',
      letterSpacing: [null, '0.7px'],
    },
  })
);

const FlightStopTitle = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: 'lightGreyFourth',
    alignItems: 'center',
    justifyContent: 'center',
    py: ['1px', 2],
    fontSize: [1, 2],
    my: '20px',
    '.stop-duration': {
      fontWeight: 'bold',
      mr: [1, 2],
    },
  })
);

const FlightDetailsContentWrapper = styled.div({
  display: 'flex',
});

const FlightDetailsTimeWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mr: [4, '40px'],
    '.flight-time': {
      fontSize: [null, '25px'],
      fontWeight: 'bold',
      letterSpacing: [null, '0.7px'],
    },
  })
);

const FlightTimeMarker = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    height: ['93px', '164px'],
    width: ['70px', '100px'],
    '&:after': {
      content: "''",
      width: ['5px', '7px'],
      height: ['5px', '7px'],
      position: 'absolute',
      bottom: ['5px', '12px'],
      left: '50%',
      transform: 'translateX(-50%)',
      background: 'black',
      borderRadius: '50%',
    },
    '&:before': {
      content: "''",
      height: ['74px', '130px'],
      position: 'absolute',
      width: '1px',
      backgroundColor: 'black',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '.flight-marker-image': {
      width: ['70px', '90px'],
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '1',
      backgroundColor: 'white',
    },
  })
);

const FlightDetailsTextWrapper = styled.div(
  sx2CssThemeFn({
    py: [null, 1],
    '.airport-description': {
      fontSize: [null, 3],
      letterSpacing: [null, '0.7px'],
    },
    '.flight-detail': {
      color: 'secondary',
      py: ['15px', '39px'],
      fontSize: [1, '18px'],
      lineHeight: [null, '1.7'],
    },
  })
);

const FlightDetails: React.FC<IFlightDetailsProps> = ({ flight, testId }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['frl', 'common']);
  const stops = getStopsInfo(flight);

  return (
    <FlightDetailsWrapper data-id={testId}>
      <DescriptionWrapper>
        <div className="flight-route">
          {flight?.departure.iata} – {flight?.arrival.iata}
        </div>
        <div className="flight-date">
          {formatDate(
            parseDateStr(flight?.departureDate),
            FLIGHT_DATE_FORMAT,
            language
          )}
          ,
          {stops.amount > 0
            ? ` ${t('stopsIn.count', { count: stops.amount })} ${stops.label}`
            : ` ${t('directFlight.label')}`}
        </div>
      </DescriptionWrapper>
      {flight?.segments.map(
        (segment, i): ReactNode => (
          <Fragment key={i}>
            {i !== 0 && stops.amount > 0 && (
              <>
                <FlightStopTitle>
                  <span className="stop-duration">
                    {segment.airportWaiting}
                  </span>
                  <span>{`${t('stopoverIn.label')} ${
                    segment.departure.name
                  }`}</span>
                </FlightStopTitle>
              </>
            )}
            <FlightDetailsContentWrapper>
              <FlightDetailsTimeWrapper>
                <div className="flight-time">{segment?.departureTime}</div>
                <FlightTimeMarker>
                  <Image
                    resized={[]}
                    className="flight-marker-image"
                    src={`https://assets.hotelplan.com/content/airline-logos/${segment?.airline.iata}_2x.png`}
                    alt={'airline logo'}
                  />
                </FlightTimeMarker>
                <div className="flight-time">{segment?.arrivalTime}</div>
              </FlightDetailsTimeWrapper>
              <FlightDetailsTextWrapper>
                <div className="airport-description">
                  {segment?.departure.iata} – {segment?.departure.name}
                </div>
                <div className="flight-detail">
                  <div>
                    {t('flight.duration')}: {segment?.segmentDuration}
                  </div>
                  <div>
                    {segment?.airline.name} {segment?.flightNumber}
                  </div>
                  <div>{t(mapFlightClass[segment?.flightClass].label)}</div>
                </div>
                <div className="airport-description">
                  {segment?.arrival.iata} – {segment?.arrival.name}
                </div>
              </FlightDetailsTextWrapper>
            </FlightDetailsContentWrapper>
          </Fragment>
        )
      )}
    </FlightDetailsWrapper>
  );
};

export default FlightDetails;
