import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import {
  AutocompleteComboboxInput,
  IAutocompleteComboboxInputProps,
  AutocompleteOption,
} from '@hotelplan/components.common.autocomplete';
import { useArrayNestedField } from '@hotelplan/components.common.forms';
import { HighlightText } from '@hotelplan/components.common.highlight';
import { Icon } from '@hotelplan/components.common.icon';
import { FakeInput } from '@hotelplan/components.common.inputs';
import { TravelType } from '@hotelplan/graphql.types';
import { trackFlightAirportsSelect } from '@hotelplan/libs.tracking';
import { mapCanonicalFlightAirportToTrackableFlightAirport } from 'components/domain/flightAirport/FlightAirport.mappers';
import {
  ICanonicalAirport,
  IFlightAirportAutocompleteFieldProps,
} from 'components/domain/flightAirport/FlightAirport.types';
import SearchHistoryTopDestinationComboSuggestions from 'components/domain/history/SearchHistoryTopDestinationComboSuggestions';

const renderDestinationLabel = (label: React.ReactNode, isGroup?: boolean) => (
  <AutocompleteOption
    icon={<Icon name={isGroup ? 'airport' : 'flug'} />}
    label={label}
  />
);

const renderDestinationSuggestionLabel = (
  item: ICanonicalAirport,
  query: string
): React.ReactNode =>
  renderDestinationLabel(
    <HighlightText text={item.name || ''} substring={query} />,
    item.isGroup
  );

const FlightAirportFakeInput = styled(FakeInput).attrs({
  testId: 'flight-airport-fake-input',
})(({ theme: { media } }) => ({
  input: {
    height: '45px',
    [media.tablet]: {
      height: '48px',
    },
  },
}));

const FlightAirportComboboxInput = styled(AutocompleteComboboxInput)<{
  customLabel?: React.ReactNode;
}>(({ theme: { media }, customLabel }) => ({
  input: {
    height: '45px',
    [media.tablet]: {
      height: '48px',
    },
  },
  '.autocomplete-dropdown': {
    top: Boolean(customLabel) ? '28px' : 0,
  },
})) as React.FC<IAutocompleteComboboxInputProps<ICanonicalAirport>>;

const FlightAirportField: React.FC<IFlightAirportAutocompleteFieldProps> = props => {
  const {
    children,
    name,
    label,
    disabled,
    placeholder,
    parentItemIndex,
    parentItemName,
    className,
    customLabel,
  } = props;

  const [t] = useTranslation('forms');
  const [airport, setAirport] = useArrayNestedField<ICanonicalAirport | null>(
    name,
    parentItemName,
    parentItemIndex
  );

  if (disabled) {
    return (
      <FlightAirportFakeInput
        icon={name === 'departureAirport' ? 'flug' : 'flug-arrival'}
        placeholder={t(`${name}.placeholder`)}
        value={airport?.name || ''}
        className={className}
        customLabel={customLabel}
      />
    );
  }

  return (
    <FlightAirportComboboxInput
      noMobileControls
      name={name}
      label={label}
      placeholder={placeholder}
      icon={{ name: name === 'departureAirport' ? 'flug' : 'flug-arrival' }}
      value={airport ? [airport] : []}
      onChange={(nextItems): void => {
        setAirport(nextItems[0]);
      }}
      onOptionSelect={(suggestion, text, popupControl) => {
        popupControl.apply();
        trackFlightAirportsSelect(
          mapCanonicalFlightAirportToTrackableFlightAirport(suggestion),
          text
        );
      }}
      renderSuggestion={renderDestinationSuggestionLabel}
      renderTagLabel={(item): React.ReactNode =>
        renderDestinationLabel(item.name, item.isGroup)
      }
      getTagName={(item): string => item.name || ''}
      displayValue={airport?.name || ''}
      maxItems={1}
      className={className}
      customLabel={customLabel}
    >
      {children}
      <SearchHistoryTopDestinationComboSuggestions
        travelType={TravelType.Flight}
      />
    </FlightAirportComboboxInput>
  );
};

export default FlightAirportField;
