import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import { useDeviceType } from '@hotelplan/libs.context.device-type';

interface IFlightSRLListSkeletonProps {
  counts?: number;
}

const ContentLoaderWrapper = styled.div(({ theme: { space, radii } }) => ({
  borderRadius: radii.default,
  backgroundColor: 'white',
  marginBottom: space[4],
}));

const FlightSRLListSkeleton: React.FC<IFlightSRLListSkeletonProps> = ({
  counts = 5,
}) => {
  const { mobile } = useDeviceType();

  return (
    <>
      {Array.from({ length: counts }, (_, i) => {
        return (
          <ContentLoaderWrapper key={i}>
            <ContentLoader
              uniquekey="flightSearchResultsSkeleton"
              width={mobile ? 325 : 768}
              height={mobile ? 365 : 302}
              speed={2}
              style={{ width: '100%', height: '100%', display: 'block' }}
            >
              {!mobile && (
                <>
                  <rect x="20" y="54" width="728" height="1" />
                  <rect x="20" y="178" width="728" height="1" />
                  <rect
                    width="768"
                    height="1"
                    transform="matrix(1 0 0 -1 0 231)"
                  />
                  <rect x="175" y="70" width="92" height="14" rx="7" />
                  <rect x="74" y="17" width="101" height="18" rx="7" />
                  <rect x="196" y="17" width="96" height="18" rx="7" />
                  <rect x="312" y="17" width="96" height="18" rx="7" />
                  <rect x="244" y="195" width="121" height="18" rx="7" />
                  <rect x="471" y="248" width="246" height="35" rx="15" />
                  <rect x="120" y="195" width="106" height="18" rx="7" />
                  <rect x="54" y="195" width="49" height="18" rx="7" />
                  <rect x="421" y="70" width="50" height="14" rx="7" />
                  <rect x="421" y="93" width="50" height="14" rx="7" />
                  <rect x="421" y="123" width="50" height="14" rx="7" />
                  <rect x="421" y="146" width="50" height="14" rx="7" />
                  <rect x="185" y="91" width="82" height="17" rx="7" />
                  <rect x="29" y="70" width="100" height="37" />
                  <rect x="29" y="123" width="100" height="37" />
                  <rect x="627" y="91" width="82" height="17" rx="7" />
                  <rect x="627" y="144" width="82" height="17" rx="7" />
                  <rect x="185" y="144" width="82" height="17" rx="7" />
                  <rect x="175" y="123" width="92" height="14" rx="7" />
                  <rect x="627" y="70" width="92" height="14" rx="7" />
                  <rect x="627" y="123" width="92" height="14" rx="7" />
                  <rect x="306" y="88" width="276" height="1" />
                  <rect x="306" y="141" width="276" height="1" />
                  <circle cx="582.5" cy="88.5" r="3.5" />
                  <circle cx="40" cy="26" r="13" />
                  <circle cx="741" cy="266" r="13" />
                  <circle cx="38" cy="203" r="10" />
                  <circle cx="582.5" cy="141.5" r="3.5" />
                </>
              )}
              {mobile && (
                <>
                  <rect x="22" y="153" width="42" height="20" />
                  <rect
                    width="293"
                    height="1"
                    transform="matrix(1 0 0 -1 16 46)"
                  />
                  <rect
                    width="293"
                    height="1"
                    transform="matrix(1 0 0 -1 16 138)"
                  />
                  <rect
                    width="92"
                    height="1"
                    transform="matrix(1 0 0 -1 131 172)"
                  />
                  <rect
                    width="293"
                    height="1"
                    transform="matrix(1 0 0 -1 16 214)"
                  />
                  <rect
                    width="293"
                    height="1"
                    transform="matrix(1 0 0 -1 16 255)"
                  />
                  <rect
                    width="293"
                    height="1"
                    transform="matrix(1 0 0 -1 16 304)"
                  />
                  <rect
                    width="325"
                    height="1"
                    transform="matrix(1 0 0 -1 0 328)"
                  />
                  <rect x="171" y="338" width="120" height="17" rx="8.5" />
                  <rect x="60" y="275" width="120" height="16" rx="8" />
                  <rect x="81" y="181" width="30" height="16" rx="8" />
                  <rect x="246" y="181" width="30" height="16" rx="8" />
                  <rect x="246" y="153" width="39" height="16" rx="8" />
                  <rect x="72" y="155" width="39" height="16" rx="8" />
                  <rect x="60" y="226" width="144" height="16" rx="8" />
                  <rect x="60" y="17" width="167" height="16" rx="8" />
                  <rect x="246" y="17" width="63" height="16" rx="8" />
                  <circle cx="307" cy="346" r="10" />
                  <circle cx="33" cy="283" r="10" />
                  <circle cx="33" cy="234" r="10" />
                  <circle cx="224.5" cy="171.5" r="2.5" />
                  <rect x="22" y="69" width="42" height="20" />
                  <rect
                    width="92"
                    height="1"
                    transform="matrix(1 0 0 -1 131 88)"
                  />
                  <rect x="81" y="97" width="30" height="16" rx="8" />
                  <rect x="246" y="97" width="30" height="16" rx="8" />
                  <rect x="246" y="69" width="39" height="16" rx="8" />
                  <rect x="72" y="71" width="39" height="16" rx="8" />
                  <rect x="153" y="95" width="52" height="12" rx="6" />
                  <rect x="158" y="69" width="41" height="12" rx="6" />
                  <rect x="153" y="179" width="52" height="12" rx="6" />
                  <rect x="158" y="153" width="41" height="12" rx="6" />
                  <circle cx="224.5" cy="87.5" r="2.5" />
                  <circle cx="34" cy="25" r="10" />
                </>
              )}
            </ContentLoader>
          </ContentLoaderWrapper>
        );
      })}
    </>
  );
};

export default FlightSRLListSkeleton;
