import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetFlightSrlStopoverDurationFilterCountsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.FlightSrlFilterCriteriaInput>;
  searchControl: Types.FlightSearchControlComponentInput;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetFlightSrlStopoverDurationFilterCountsQuery = {
  __typename?: 'Query';
  flightSrl: {
    __typename?: 'FlightSrlContext';
    content: {
      __typename?: 'FlightSrlContainer';
      flights: {
        __typename?: 'FlightSrlComponent';
        stopOverDuration: {
          __typename?: 'FlightStopOverDuration';
          maxDuration: string;
          minDuration: string;
        };
      };
    };
  };
};

export const GetFlightSrlStopoverDurationFilterCountsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFlightSRLStopoverDurationFilterCounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'FlightSrlFilterCriteriaInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchControl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'FlightSearchControlComponentInput',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightSrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flights' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {
                              kind: 'Name',
                              value: 'flightSrlSearchCriteria',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'page' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'pageNumber',
                                        },
                                        value: { kind: 'IntValue', value: '0' },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'resultsPerPage',
                                        },
                                        value: { kind: 'IntValue', value: '5' },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'sort' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'field' },
                                        value: {
                                          kind: 'EnumValue',
                                          value: 'PRICE',
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'filter' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'searchControl',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'searchControl',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stopOverDuration' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'maxDuration',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'minDuration',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;

/**
 * __useGetFlightSrlStopoverDurationFilterCountsQuery__
 *
 * To run a query within a React component, call `useGetFlightSrlStopoverDurationFilterCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlightSrlStopoverDurationFilterCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlightSrlStopoverDurationFilterCountsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      searchControl: // value for 'searchControl'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetFlightSrlStopoverDurationFilterCountsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFlightSrlStopoverDurationFilterCountsQuery,
    GetFlightSrlStopoverDurationFilterCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFlightSrlStopoverDurationFilterCountsQuery,
    GetFlightSrlStopoverDurationFilterCountsQueryVariables
  >(GetFlightSrlStopoverDurationFilterCountsDocument, options);
}
export function useGetFlightSrlStopoverDurationFilterCountsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFlightSrlStopoverDurationFilterCountsQuery,
    GetFlightSrlStopoverDurationFilterCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFlightSrlStopoverDurationFilterCountsQuery,
    GetFlightSrlStopoverDurationFilterCountsQueryVariables
  >(GetFlightSrlStopoverDurationFilterCountsDocument, options);
}
export type GetFlightSrlStopoverDurationFilterCountsQueryHookResult = ReturnType<
  typeof useGetFlightSrlStopoverDurationFilterCountsQuery
>;
export type GetFlightSrlStopoverDurationFilterCountsLazyQueryHookResult = ReturnType<
  typeof useGetFlightSrlStopoverDurationFilterCountsLazyQuery
>;
export type GetFlightSrlStopoverDurationFilterCountsQueryResult = Apollo.QueryResult<
  GetFlightSrlStopoverDurationFilterCountsQuery,
  GetFlightSrlStopoverDurationFilterCountsQueryVariables
>;
