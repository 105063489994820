import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { RadiobuttonGroupFilterValueFragmentDoc } from 'graphql/searchFilter/RadiobuttonGroupFilterValue.generated';
import { DoubleRangeSliderFilterFragmentDoc } from 'graphql/searchFilter/DoubleRangeSliderFilter.generated';
import { SliderFilterFragmentDoc } from 'graphql/searchFilter/SliderFilter.generated';
import { CheckboxGroupFilterValueFragmentDoc } from 'graphql/searchFilter/CheckboxGroupFilterValue.generated';
export type FlightSrlFilterValuesFragment = {
  __typename?: 'FilterComponent';
  stopOvers: { __typename?: 'RadiobuttonFilterComponent'; selected: string };
  flightStopOverDuration: {
    __typename?: 'DoubleRangeSliderFilterComponent';
    caption: string;
    maxSelected: string;
    minSelected: string;
  };
  directFlightDepartureTime: {
    __typename?: 'RadiobuttonFilterComponent';
    selected: string;
  };
  directFlightArrivalTime: {
    __typename?: 'RadiobuttonFilterComponent';
    selected: string;
  };
  returnFlightDepartureTime: {
    __typename?: 'RadiobuttonFilterComponent';
    selected: string;
  };
  returnFlightArrivalTime: {
    __typename?: 'RadiobuttonFilterComponent';
    selected: string;
  };
  maxPrice: {
    __typename?: 'SliderFilterComponent';
    caption: string;
    selected: string;
  };
  flightAirlines: {
    __typename?: 'CheckboxFilterComponent';
    selected: Array<string>;
  };
};

export const FlightSrlFilterValuesFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlightSRLFilterValues' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FilterComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stopOvers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightStopOverDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DoubleRangeSliderFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'directFlightDepartureTime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'directFlightArrivalTime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnFlightDepartureTime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnFlightArrivalTime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SliderFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightAirlines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterValue' },
                },
              ],
            },
          },
        ],
      },
    },
    ...RadiobuttonGroupFilterValueFragmentDoc.definitions,
    ...DoubleRangeSliderFilterFragmentDoc.definitions,
    ...SliderFilterFragmentDoc.definitions,
    ...CheckboxGroupFilterValueFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
