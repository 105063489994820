import noop from 'lodash/noop';
import React, { useState, useContext } from 'react';
import { FlightSortField } from '@hotelplan/graphql.types';

interface IFlightSRLSortingState {
  sortingValue: FlightSortField;
  setSortingValue: (nextSortingValue: FlightSortField) => void;
}

const FlightSRLSortingContext = React.createContext<IFlightSRLSortingState>({
  sortingValue: FlightSortField.Price,
  setSortingValue: noop,
});

export const useFlightSRLSortingContext = (): IFlightSRLSortingState => {
  return useContext(FlightSRLSortingContext);
};

export const FlightSRLSortingContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [sortingValue, setSortingValue] = useState(FlightSortField.Price);

  return (
    <FlightSRLSortingContext.Provider
      value={{
        sortingValue,
        setSortingValue,
      }}
    >
      {children}
    </FlightSRLSortingContext.Provider>
  );
};
