import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { RadiobuttonGroupFilterOptionFragmentDoc } from 'graphql/searchFilter/RadiobuttonGroupFilterOption.generated';
import { DoubleRangeSliderFilterFragmentDoc } from 'graphql/searchFilter/DoubleRangeSliderFilter.generated';
import { SliderFilterFragmentDoc } from 'graphql/searchFilter/SliderFilter.generated';
import { CheckboxGroupFilterOptionFragmentDoc } from 'graphql/searchFilter/CheckboxGroupFilterOption.generated';
export type FlightSrlFilterOptionsFragment = {
  __typename?: 'FilterComponent';
  stopOvers: {
    __typename?: 'RadiobuttonFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  flightStopOverDuration: {
    __typename?: 'DoubleRangeSliderFilterComponent';
    caption: string;
    maxSelected: string;
    minSelected: string;
  };
  directFlightDepartureTime: {
    __typename?: 'RadiobuttonFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  directFlightArrivalTime: {
    __typename?: 'RadiobuttonFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  returnFlightDepartureTime: {
    __typename?: 'RadiobuttonFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  returnFlightArrivalTime: {
    __typename?: 'RadiobuttonFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
  maxPrice: {
    __typename?: 'SliderFilterComponent';
    caption: string;
    selected: string;
  };
  flightAirlines: {
    __typename?: 'CheckboxFilterComponent';
    caption: string;
    options: Array<{ __typename?: 'FilterItem'; id: string; caption: string }>;
  };
};

export const FlightSrlFilterOptionsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlightSRLFilterOptions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FilterComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stopOvers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightStopOverDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DoubleRangeSliderFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'directFlightDepartureTime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'directFlightArrivalTime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnFlightDepartureTime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnFlightArrivalTime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RadiobuttonGroupFilterOption' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SliderFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightAirlines' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CheckboxGroupFilterOption' },
                },
              ],
            },
          },
        ],
      },
    },
    ...RadiobuttonGroupFilterOptionFragmentDoc.definitions,
    ...DoubleRangeSliderFilterFragmentDoc.definitions,
    ...SliderFilterFragmentDoc.definitions,
    ...CheckboxGroupFilterOptionFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
