import React, { useContext } from 'react';
import { trackSearch } from '@hotelplan/libs.tracking';
import CurrentSearchContext from 'components/domain/currentSearch/CurrentSearchContext';
import { mapFlightSRLStateToTrackableData } from './FlightSRL.mappers';
import FlightSRLFormCurrentSearchField from './FlightSRLFormCurrentSearchField';
import FlightSRLSearchControlFormContainer from './FlightSRLSearchControlFormContainer';
import FlightSRLSearchForm from './FlightSRLSearchForm';

const FlightSRLSearchFormToggle: React.FC = () => {
  const {
    closeSearchControl,
    isSearchControlShowed,
    showSearchControl,
    ref,
  } = useContext(CurrentSearchContext);

  return (
    <div ref={ref}>
      {isSearchControlShowed ? (
        <FlightSRLSearchForm
          postSubmit={state => {
            trackSearch(mapFlightSRLStateToTrackableData(state));
          }}
        >
          <FlightSRLSearchControlFormContainer
            handleCancel={closeSearchControl}
          />
        </FlightSRLSearchForm>
      ) : (
        <FlightSRLFormCurrentSearchField handleEdit={showSearchControl} />
      )}
    </div>
  );
};

export default FlightSRLSearchFormToggle;
