import {
  mapMainFilterComponentOptionsToLocalFilterOptions,
  mapMainFilterComponentOptionsToLocalFilterOptionCaptions,
} from 'components/domain/filters/Filters.mappers';
import { IFilterOptionsResult } from 'components/domain/filters/Filters.types';
import { useGetFlightSrlFilterOptionsQuery } from 'graphql/flightSRL/GetFlightSRLFilterOptions.generated';
import { MainFilterComponentOptionsFragment } from 'graphql/searchFilter/MainFilterComponentOptions.generated';
import useFlightSRLAirlineOptions from './useGetFlightSRLAirlineOptions';

export default function useFlightSRLFilterOptions(): IFilterOptionsResult {
  const { data, loading } = useGetFlightSrlFilterOptionsQuery();

  const commonOptions: IFilterOptionsResult['options'] = data?.flightSrl.filters
    ? mapMainFilterComponentOptionsToLocalFilterOptions(
        data.flightSrl.filters as MainFilterComponentOptionsFragment
      )
    : null;
  const dynamicOptions = useFlightSRLAirlineOptions();
  const options = commonOptions
    ? { ...commonOptions, flightAirlines: dynamicOptions }
    : null;
  const optionCaptions = data?.flightSrl.filters
    ? mapMainFilterComponentOptionsToLocalFilterOptionCaptions(
        data.flightSrl.filters as MainFilterComponentOptionsFragment
      )
    : null;

  return {
    loading,
    options,
    optionCaptions,
  };
}
