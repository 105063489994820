import addDays from 'date-fns/addDays';
import React, { useEffect } from 'react';
import { useField, useFormContext } from '@hotelplan/components.common.forms';
import type { ITravelDatesState } from '@hotelplan/components.common.travel-dates';
import { FlightType } from '@hotelplan/graphql.types';
import { getTodaysDate } from '@hotelplan/libs.utils';
import { prepareDatesInFlightPartitions } from 'components/domain/flightPartitions/FlightPartitions.utils';
import { useFlightPartitions } from 'components/domain/flightPartitions/useFlightPartitions';
import SearchTravelDates from 'components/domain/search-travel-dates/search-travel-dates';
import { IFlightSearchControlFormState } from 'components/domain/searchControl/SearchControl.types';
import {
  FlightFormFieldTypes,
  useFlightFormContext,
} from 'components/domain/searchControl/useFlightFormContext';

const FlightTravelDatesField: React.FC<{
  partitionIndex: number;
  customLabel?: React.ReactNode;
}> = ({ partitionIndex, customLabel }) => {
  const { onFlightFormChange } = useFlightFormContext();
  const { value: flightPartitions } = useFlightPartitions();
  const [activeType] = useField<FlightType | null>('flightType');
  const { values, onChange } = useFormContext<IFlightSearchControlFormState>();

  // NOTE: The second partition date can not be earlier than first partition date.
  const secondPartitionMinDate = flightPartitions
    ? flightPartitions[0].travelDates?.departureDate
    : undefined;

  useEffect(() => {
    if (flightPartitions) {
      onChange({
        ...values,
        flightPartitions: prepareDatesInFlightPartitions(flightPartitions),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchTravelDates
      noVariants
      parentItemIndex={partitionIndex}
      parentItemName="flightPartitions"
      customConfiguration={{
        onlyExact: true,
        onlyDatesInfo: true,
        closeOnSelection: true,
        onlyDepartureDate: activeType !== FlightType.Return,
        setReturnDateAsDeparture: activeType !== FlightType.Return,
        maxDate: addDays(getTodaysDate(), 365),
        minDate: partitionIndex === 1 ? secondPartitionMinDate : undefined,
        flightActiveType: activeType,
      }}
      onChange={(travelDates: ITravelDatesState | null) => {
        onFlightFormChange(
          travelDates,
          FlightFormFieldTypes.TravelDatesField,
          partitionIndex
        );
      }}
      side="rightSide"
      customLabel={customLabel}
    />
  );
};

export default FlightTravelDatesField;
