import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { FlightSearchControlFragmentDoc } from 'graphql/flight/FlightSearchControl.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetFlightSrlDefaultSearchControlValuesQueryVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetFlightSrlDefaultSearchControlValuesQuery = {
  __typename?: 'Query';
  flightSrl: {
    __typename?: 'FlightSrlContext';
    searchControl: {
      __typename?: 'FlightSearchControlComponent';
      travelType: Types.TravelType;
      flightType: Types.FlightType;
      travellers: {
        __typename?: 'Travellers';
        adults: number;
        childrenDobs?: Array<string> | null;
      };
      flightPartitions?: Array<{
        __typename?: 'FlightPartition';
        flightClass: Types.FlightClass;
        departureAirport?: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        } | null;
        arrivalAirport?: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        } | null;
        travelPeriod: {
          __typename?: 'ExactTravelPeriod';
          returnDate: string;
          departureDate: string;
        };
      }> | null;
    };
  };
};

export const GetFlightSrlDefaultSearchControlValuesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFlightSRLDefaultSearchControlValues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightSrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchControl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'encodedCriteria' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'FlightSearchControl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FlightSearchControlFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetFlightSrlDefaultSearchControlValuesQuery__
 *
 * To run a query within a React component, call `useGetFlightSrlDefaultSearchControlValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlightSrlDefaultSearchControlValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlightSrlDefaultSearchControlValuesQuery({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetFlightSrlDefaultSearchControlValuesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetFlightSrlDefaultSearchControlValuesQuery,
    GetFlightSrlDefaultSearchControlValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFlightSrlDefaultSearchControlValuesQuery,
    GetFlightSrlDefaultSearchControlValuesQueryVariables
  >(GetFlightSrlDefaultSearchControlValuesDocument, options);
}
export function useGetFlightSrlDefaultSearchControlValuesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFlightSrlDefaultSearchControlValuesQuery,
    GetFlightSrlDefaultSearchControlValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFlightSrlDefaultSearchControlValuesQuery,
    GetFlightSrlDefaultSearchControlValuesQueryVariables
  >(GetFlightSrlDefaultSearchControlValuesDocument, options);
}
export type GetFlightSrlDefaultSearchControlValuesQueryHookResult = ReturnType<
  typeof useGetFlightSrlDefaultSearchControlValuesQuery
>;
export type GetFlightSrlDefaultSearchControlValuesLazyQueryHookResult = ReturnType<
  typeof useGetFlightSrlDefaultSearchControlValuesLazyQuery
>;
export type GetFlightSrlDefaultSearchControlValuesQueryResult = Apollo.QueryResult<
  GetFlightSrlDefaultSearchControlValuesQuery,
  GetFlightSrlDefaultSearchControlValuesQueryVariables
>;
