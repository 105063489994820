import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
export type DoubleRangeSliderFilterFragment = {
  __typename?: 'DoubleRangeSliderFilterComponent';
  caption: string;
  maxSelected: string;
  minSelected: string;
};

export const DoubleRangeSliderFilterFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DoubleRangeSliderFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DoubleRangeSliderFilterComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxSelected' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minSelected' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
