import type { TFunction } from 'next-i18next';
import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { useField } from '@hotelplan/components.common.forms';
import { Icon } from '@hotelplan/components.common.icon';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { FlightType } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { sx2CssThemeFn, SxStyleProp } from '@hotelplan/util.theme.sxc';
import CustomLabel from 'components/domain/CustomLabel';
import FlightClassField from 'components/domain/flightClass/FlightClassField';
import FlightPartitions from 'components/domain/flightPartitions/FlightPartitions';
import FlightTypesField from 'components/domain/flightTypes/FlightTypesField';
import TravelRoomsFieldContainer from 'components/domain/travelRooms/TravelRoomsFieldContainer';

interface IFlightFormLayoutProps {
  renderDepartureAirportField: (
    t: TFunction,
    partitionId: number
  ) => React.ReactNode;
  renderArrivalAirportField: (
    t: TFunction,
    partitionId: number
  ) => React.ReactNode;
  isSubmitAllowed?: boolean;
  handleCancel?: () => void;
  className?: string;
  withCustomLabels?: boolean;
}

const FlightFormLayoutWrapper = styled.div(
  ({ theme: { colors } }) =>
    sx2CssThemeFn({
      mx: [4, 0],
      '.flight-param-wrapper': {
        'select:focus-visible': {
          outline: `auto 2px`,
          outlineOffset: '-1px',
          outlineColor: colors.defaultText,
        },
      },
    }),
  ({ theme: { media, space } }) => ({
    '.flight-types-wrapper': {
      marginBottom: space[3],
      [media.tablet]: {
        marginBottom: space[4],
      },
    },
  })
);

const inputStyles: SxStyleProp = {
  flex: ['100%', '1', '1 1 405px'],
  '&:not(:last-child)': {
    mr: [0, '10px'],
  },
  '> .input': {
    height: ['45px', '48px'],
  },
  'select button': {
    height: [null, '48px'],
  },
  '> .icon:not(.chevron)': {
    variant: ['icons.md', 'icons.lg'],
  },
};

const FlightParamsWrapper = styled.div<{ activeType: FlightType | null }>(
  sx2CssThemeFn({
    display: 'flex',
    '.flight-param': inputStyles,
    flexWrap: ['wrap', 'nowrap'],
    mt: [null, 4],
  }),
  ({ activeType }) =>
    activeType === FlightType.OpenJaw
      ? sx2CssThemeFn({
          borderTop: [null, '1px solid'],
          borderColor: [null, 'borderColor'],
          pt: [2, 5],
        })
      : {}
);

const FlightParamSubmitWrapper = styled.div(
  sx2CssThemeFn(inputStyles, {
    flex: ['100%', '1', '340px'],
    display: ['none', 'flex'],
    alignItems: 'flex-end',
  })
);

const FlightParamSubmitButton = styled(BsButton)(
  sx2CssThemeFn({
    width: '100%',
    height: [null, '48px'],
  })
);

const FlightFormFooterWrapper = styled.div(
  sx2CssThemeFn({
    textAlign: 'center',
    display: [null, 'none'],
  })
);

const SubmitButton = styled(BsButton)({
  width: '100%',
});

const CloseButton = styled(BsButton)({
  margin: '0 auto',
  padding: '8px 0',
});

const FlightFormFooterOptions = styled.div(({ theme: { space } }) => ({
  display: 'flex',
  marginBottom: space[2],
  justifyContent: 'flex-end',
}));

const FlightFormFooterToggleOptionsButtonWrapper = styled(BsButton)<{
  isOptionsOpen: boolean;
}>(({ isOptionsOpen, theme: { space, fontSizes, colors } }) => ({
  paddingTop: 0,
  paddingBottom: '8px',
  '.flight-options-button-text': {
    marginRight: space[2],
    fontSize: fontSizes[1],
    color: 'initial',
  },
  '.flight-options-button-icon': {
    width: '16px',
    height: '10px',
    color: colors.black,
    transform: isOptionsOpen ? 'rotate(180deg)' : 'none',
  },
}));

const FlightClassFieldWrapper = styled.div(
  sx2CssThemeFn({
    marginBottom: [3, '20px'],
    '.flight-class-field-options': {
      '.input': {
        py: ['10px', '13px'],
      },
    },
  })
);

const FlightFormLayout: React.FC<IFlightFormLayoutProps> = ({
  renderDepartureAirportField,
  renderArrivalAirportField,
  isSubmitAllowed,
  className,
  handleCancel,
  withCustomLabels,
}) => {
  const { mobile } = useDeviceType();
  const [t] = useTranslation(['common', 'forms']);
  const [activeType] = useField<FlightType | null>('flightType');
  const [isOptionsOpen, , , toggleOptions] = useToggleState(false);

  return (
    <FlightFormLayoutWrapper className={className}>
      <div className="flight-types-wrapper">
        <FlightTypesField />
      </div>
      <FlightPartitions
        renderDepartureAirportField={renderDepartureAirportField}
        renderArrivalAirportField={renderArrivalAirportField}
        withCustomLabels={withCustomLabels}
      />
      <FlightParamsWrapper activeType={activeType}>
        <div className="flight-param">
          <TravelRoomsFieldContainer
            side="leftSide"
            customLabel={
              withCustomLabels ? (
                <CustomLabel htmlFor={`travelers`}>
                  {t('forms:flightTravelers.label')}
                </CustomLabel>
              ) : null
            }
          />
        </div>
        {!mobile && (
          <div className="flight-param">
            <FlightClassField
              className="flight-param-wrapper"
              customLabel={
                withCustomLabels ? (
                  <CustomLabel>{t('forms:flightClassForm.label')}</CustomLabel>
                ) : null
              }
            />
          </div>
        )}
        {!mobile && (
          <FlightParamSubmitWrapper>
            <FlightParamSubmitButton type="submit" disabled={!isSubmitAllowed}>
              {t('common:search')}
            </FlightParamSubmitButton>
          </FlightParamSubmitWrapper>
        )}
      </FlightParamsWrapper>
      {mobile && (
        <FlightFormFooterWrapper>
          <FlightFormFooterOptions>
            <FlightFormFooterToggleOptionsButtonWrapper
              data-id="options-btn"
              variant={E_BUTTON_TYPE.LINK_BUTTON}
              onClick={e => {
                e.preventDefault();
                toggleOptions();
              }}
              isOptionsOpen={isOptionsOpen}
            >
              <span className="flight-options-button-text">
                {t('forms:flightOptions.button')}
              </span>
              <Icon
                className="flight-options-button-icon"
                name="chevron-down"
              />
            </FlightFormFooterToggleOptionsButtonWrapper>
          </FlightFormFooterOptions>
          {isOptionsOpen && (
            <FlightClassFieldWrapper>
              <FlightClassField
                className="flight-class-field-options"
                customLabel={
                  withCustomLabels ? (
                    <CustomLabel>
                      {t('forms:flightClassForm.label')}
                    </CustomLabel>
                  ) : null
                }
              />
            </FlightClassFieldWrapper>
          )}
          <SubmitButton
            data-id="searchButton"
            type="submit"
            disabled={!isSubmitAllowed}
          >
            {t('common:search')}
          </SubmitButton>
          {handleCancel && (
            <CloseButton
              variant={E_BUTTON_TYPE.LINK_BUTTON}
              type="button"
              className="cancel"
              data-id="close-btn"
              onClick={handleCancel}
            >
              {t('common:cancel')}
            </CloseButton>
          )}
        </FlightFormFooterWrapper>
      )}
    </FlightFormLayoutWrapper>
  );
};

export default FlightFormLayout;
