import { useEffect } from 'react';
import { useSearchState } from '@hotelplan/libs.search-state';
import { IFilterOption } from 'components/domain/filters/Filters.types';
import { mapFormStateToFlightSearchControlCriteriaInput } from 'components/domain/flight/Flight.mappers';
import { IFlightSearchControlFormState } from 'components/domain/searchControl/SearchControl.types';
import { useGetFlightSrlFlightAirlinesFilterCountsLazyQuery } from 'graphql/flightSRL/GetFlightSRLFlightAirlinesFilterCounts.generated';
import { mapFlightSrlFormFilterValuesToFlightSrlFilterCriteriaInput } from './FlightSRL.mappers';
import type { IFlightSRLState } from './FlightSRL.types';
import useGetFlightSRLOffers from './useGetFlightSRLOffers';

export default function useFlightSRLAirlineOptions(): IFilterOption[] {
  const {
    loading,
    state: { searchControl, filters },
  } = useSearchState<IFlightSRLState>();

  const { data: offers, loading: offersLoading } = useGetFlightSRLOffers(50);

  const [
    getAirlineOptions,
    { data: flightAirlineOptions },
  ] = useGetFlightSrlFlightAirlinesFilterCountsLazyQuery({
    ssr: false,
  });

  useEffect(() => {
    if (
      !loading &&
      !offersLoading &&
      offers.length &&
      filters?.prevChangedFilter !== 'flightAirlines'
    ) {
      getAirlineOptions({
        variables: {
          searchControl: mapFormStateToFlightSearchControlCriteriaInput(
            searchControl as IFlightSearchControlFormState
          ),
          filters: mapFlightSrlFormFilterValuesToFlightSrlFilterCriteriaInput(
            filters
          ),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offers, loading, filters, offersLoading]);

  return (
    flightAirlineOptions?.flightSrl.filters.flightAirlines.options.map(
      ({ id, caption }) => ({ id, caption } as IFilterOption)
    ) || []
  );
}
