import { useTranslation } from 'next-i18next';
import React, { useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';
import { TextLineSkeleton } from '@hotelplan/components.common.text';
import { Price, FlightOffer } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { formatPrice } from '@hotelplan/libs.utils';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import EmptyResults from 'components/domain/EmptyResults';
import { useTrackFiltering } from 'components/domain/filters/useTrackFiltering';
import FlightItem from './FlightItem';
import FlightSRLListSkeleton from './FlightSRLListSkeleton.skeleton';
import FlightSRLSortingField from './FlightSRLSortingField';
import useGetFlightSRLOffers from './useGetFlightSRLOffers';

interface IFlightSrlStatus {
  loading: boolean;
  offersCount: number;
  cheapestPrice?: Price;
  destination?: string | null;
}

const FlightSrlStatusWrapper = styled.div(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    display: 'flex',
    mb: ['20px', '33px'],
    alignItems: 'center',
    justifyContent: 'space-between',
    '.flight-srl-status': FONT_SIZE.S,
    '.flight-srl-count': {
      color: 'black',
      fontWeight: 'bold',
    },
  })
);

const FlightSRLSortingFieldWrapper = styled.div(
  sx2CssThemeFn({
    flexBasis: [null, '270px'],
    ml: [0, 3],
  })
);

const FlightSrlStatus: React.FC<IFlightSrlStatus> = props => {
  const [t] = useTranslation('frl');
  const { mobile } = useDeviceType();
  const { loading, offersCount, cheapestPrice, destination } = props;

  return (
    <FlightSrlStatusWrapper>
      <p data-id="srl-status" className="flight-srl-status">
        <span className="flight-srl-count" data-id="amount">
          {loading ? <TextLineSkeleton width={30} /> : offersCount}
        </span>{' '}
        {destination ? (
          <span>
            {t(
              offersCount > 1
                ? 'resultsOverviewMultiple.prefixDestination'
                : 'resultsOverviewSingle.prefixDestination'
            )}{' '}
            {destination} {t('resultsOverview.prefixPrice')}{' '}
          </span>
        ) : (
          <span>
            {t(
              offersCount > 1
                ? 'resultsOverviewMultiple.prefixPrice'
                : 'resultsOverviewSingle.prefixPrice'
            )}{' '}
          </span>
        )}
        {loading ? (
          <TextLineSkeleton width={70} />
        ) : (
          <span data-id="price">
            {cheapestPrice ? formatPrice(cheapestPrice) : ''}
          </span>
        )}
      </p>
      {!mobile && (
        <FlightSRLSortingFieldWrapper>
          <FlightSRLSortingField />
        </FlightSRLSortingFieldWrapper>
      )}
    </FlightSrlStatusWrapper>
  );
};

interface IFlightSRLResults {
  onCheckout: (offer: FlightOffer) => void;
}

const FlightSRLResults: React.FC<IFlightSRLResults> = ({ onCheckout }) => {
  const { setError, setStatus } = useTrackFiltering();
  const {
    data: offers,
    loading,
    nextPage,
    destination,
    offersCount,
    cheapestPrice,
    hadRealSearch,
    isCacheEmpty,
    onShowMore,
    isRequested,
  } = useGetFlightSRLOffers(50, () => {
    setError(true);
  });

  useEffect(() => {
    setStatus({ count: offersCount, loading: !isRequested || loading });
  }, [loading, offersCount, isRequested]);

  if (!loading && offers.length === 0 && (hadRealSearch || !isCacheEmpty)) {
    return <EmptyResults />;
  }

  if (!loading && offers.length === 0 && !hadRealSearch) {
    return null;
  }

  return (
    <>
      <FlightSrlStatus
        loading={loading}
        destination={destination}
        offersCount={offersCount}
        cheapestPrice={cheapestPrice as Price}
      />
      {offers.map((offer, i) => {
        const isLastItem = i === offers.length - 1;
        return (
          <VisibilitySensor
            key={i}
            active={isLastItem}
            partialVisibility={true}
            onChange={(isVisible): void => {
              const isLastItemVisible = isLastItem && isVisible;
              if (isLastItemVisible && !!nextPage) {
                onShowMore(nextPage);
              }
            }}
          >
            <FlightItem
              flight={offer?.forwardFlight}
              returnFlight={offer?.returnFlight}
              totalPrice={offer?.totalPrice as Price}
              pricePerPerson={offer?.pricePerPerson as Price}
              onCheckout={() => onCheckout(offer as FlightOffer)}
            />
          </VisibilitySensor>
        );
      })}
      {loading && <FlightSRLListSkeleton counts={25} />}
    </>
  );
};

export default FlightSRLResults;
