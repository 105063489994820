import React, { FC } from 'react';
import type { IFlightAirportFieldProps } from 'components/domain/flightAirport/FlightAirport.types';
import GenericFlightAirportField from 'components/domain/flightAirport/GenericFlightAirportField';
import {
  GetFlightSrlAirportAutocompleteQuery,
  GetFlightSrlAirportAutocompleteDocument,
  GetFlightSrlAirportAutocompleteQueryVariables,
} from 'graphql/search/GetFlightAirportAutocomplete.generated';

const FlightSRLAirportField: FC<IFlightAirportFieldProps> = ({
  name,
  disabled,
  label,
  placeholder,
  parentItemIndex,
  customLabel,
}) => {
  return (
    <GenericFlightAirportField<
      GetFlightSrlAirportAutocompleteQuery,
      GetFlightSrlAirportAutocompleteQueryVariables
    >
      name={name}
      label={label}
      disabled={disabled}
      placeholder={placeholder}
      parentItemIndex={parentItemIndex}
      parentItemName="flightPartitions"
      queryDocument={GetFlightSrlAirportAutocompleteDocument}
      dataMapper={data =>
        data
          ? {
              destinations: data.flightSrl.autocomplete.destinations,
              page: data.flightSrl.autocomplete.page,
            }
          : undefined
      }
      variablesMapper={variables => ({ ...variables })}
      customLabel={customLabel}
    />
  );
};

export default FlightSRLAirportField;
