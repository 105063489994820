import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { SelectInput } from '@hotelplan/components.common.select-input';
import { FlightSortField } from '@hotelplan/graphql.types';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import type { ISortingOption } from './FlightSRL.types';
import { useFlightSRLSortingContext } from './FlightSRLSortingResultsProvider';

const SelectInputWrapper = styled(SelectInput)(
  sx2CssThemeFn({
    '.input': {
      py: '15px',
      lineHeight: 'normal',
    },
  })
);

const FlightSRLSortingField: React.FC = () => {
  const { t } = useTranslation(['frl', 'results']);

  const { sortingValue, setSortingValue } = useFlightSRLSortingContext();

  const options = useMemo(() => {
    return [
      {
        label: t('frl:sort.price'),
        value: FlightSortField.Price,
      },
      {
        label: t('frl:sort.duration'),
        value: FlightSortField.Duration,
      },
      {
        label: t('frl:sort.departureTime'),
        value: FlightSortField.DepartureTime,
      },
    ] as ISortingOption[];
  }, [t]);

  return (
    <SelectInputWrapper
      hideLabel
      options={options}
      value={sortingValue}
      label={t('results:sort.label')}
      onChange={(nextSortOption: FlightSortField) => {
        const option = options.find(({ value }) => value === nextSortOption);

        if (option) {
          setSortingValue(option.value);
        }
      }}
    />
  );
};

export default FlightSRLSortingField;
