import React, { useRef } from 'react';
import { Form, IFormApi } from '@hotelplan/components.common.forms';
import { useGSSContext } from '@hotelplan/libs.gss';
import {
  useSearchState,
  useOnSearchStateChanges,
} from '@hotelplan/libs.search-state';
import { isBrowser } from '@hotelplan/libs.utils';
import CurrentSearchSkeleton from 'components/domain/currentSearch/CurrentSearch.skeleton';
import { useCurrentSearchContext } from 'components/domain/currentSearch/CurrentSearchContext';
import { useFilterCountErrorsContext } from 'components/domain/filters/FilterCountErrorsContext';
import { IFlightSearchControlFormState } from 'components/domain/searchControl/SearchControl.types';
import { useAddHistoryItem } from 'components/domain/searchControl/useAddHistoryItem';
import { useGetFlightSrlDefaultSearchControlValuesQuery } from 'graphql/flightSRL/GetFlightSRLDefaultSearchControlValues.generated';
import { mapFlightSRLSearchControlValuesToFormState } from './FlightSRL.mappers';
import type { IFlightSRLState } from './FlightSRL.types';
import { useFlightSearchTimestamps } from './useFligthSRLTimestamps';

const FlightSRLSearchForm: React.FC<{
  postSubmit?: (state: IFlightSRLState) => void;
  children?: React.ReactNode;
}> = ({ children, postSubmit }) => {
  const { saveWithErrors } = useFilterCountErrorsContext();
  const { setFGSS } = useGSSContext<unknown, IFlightSearchControlFormState>();
  const { closeSearchControl, formRef } = useCurrentSearchContext();
  const { setFlightSearchRequestTimestamp } = useFlightSearchTimestamps();
  const formApiRef = useRef<IFormApi<IFlightSearchControlFormState>>(null);

  const {
    data: defaultSearchControlValues,
    loading: defaultSearchControlLoading,
  } = useGetFlightSrlDefaultSearchControlValuesQuery();

  const {
    loading,
    state: { searchControl },
    setState,
  } = useSearchState<IFlightSRLState>();

  const addHistoryItem = useAddHistoryItem();

  useOnSearchStateChanges<IFlightSRLState>(nextValues => {
    formApiRef.current?.setValues(
      nextValues.searchControl as IFlightSearchControlFormState
    );
  });

  if (
    ((loading || defaultSearchControlLoading) && !searchControl) ||
    !isBrowser
  )
    return <CurrentSearchSkeleton />;

  return (
    searchControl && (
      <Form<IFlightSearchControlFormState>
        formRef={formRef}
        formApiRef={formApiRef}
        initialValues={searchControl}
        onSubmit={(nextModel): void => {
          setState(
            prev => ({
              ...prev,
              searchControl: nextModel,
              filters: { ...prev.filters, prevChangedFilter: undefined },
            }),
            postSubmit
          );
          saveWithErrors(false);
          setFGSS(
            defaultSearchControlValues
              ? mapFlightSRLSearchControlValuesToFormState(
                  defaultSearchControlValues.flightSrl.searchControl
                )
              : {},
            nextModel
          );
          addHistoryItem({ ...nextModel, type: 'FLIGHT' });
          setFlightSearchRequestTimestamp();
          closeSearchControl();
        }}
      >
        {children}
      </Form>
    )
  );
};

export default FlightSRLSearchForm;
