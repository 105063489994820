import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { FlightSrlFilterOptionsFragmentDoc } from './FlightSRLFilterOptions.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetFlightSrlFilterOptionsQueryVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetFlightSrlFilterOptionsQuery = {
  __typename?: 'Query';
  flightSrl: {
    __typename?: 'FlightSrlContext';
    filters: {
      __typename?: 'FilterComponent';
      stopOvers: {
        __typename?: 'RadiobuttonFilterComponent';
        caption: string;
        options: Array<{
          __typename?: 'FilterItem';
          id: string;
          caption: string;
        }>;
      };
      flightStopOverDuration: {
        __typename?: 'DoubleRangeSliderFilterComponent';
        caption: string;
        maxSelected: string;
        minSelected: string;
      };
      directFlightDepartureTime: {
        __typename?: 'RadiobuttonFilterComponent';
        caption: string;
        options: Array<{
          __typename?: 'FilterItem';
          id: string;
          caption: string;
        }>;
      };
      directFlightArrivalTime: {
        __typename?: 'RadiobuttonFilterComponent';
        caption: string;
        options: Array<{
          __typename?: 'FilterItem';
          id: string;
          caption: string;
        }>;
      };
      returnFlightDepartureTime: {
        __typename?: 'RadiobuttonFilterComponent';
        caption: string;
        options: Array<{
          __typename?: 'FilterItem';
          id: string;
          caption: string;
        }>;
      };
      returnFlightArrivalTime: {
        __typename?: 'RadiobuttonFilterComponent';
        caption: string;
        options: Array<{
          __typename?: 'FilterItem';
          id: string;
          caption: string;
        }>;
      };
      maxPrice: {
        __typename?: 'SliderFilterComponent';
        caption: string;
        selected: string;
      };
      flightAirlines: {
        __typename?: 'CheckboxFilterComponent';
        caption: string;
        options: Array<{
          __typename?: 'FilterItem';
          id: string;
          caption: string;
        }>;
      };
    };
  };
};

export const GetFlightSrlFilterOptionsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFlightSRLFilterOptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightSrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'encodedCriteria' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'FlightSRLFilterOptions' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FlightSrlFilterOptionsFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetFlightSrlFilterOptionsQuery__
 *
 * To run a query within a React component, call `useGetFlightSrlFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlightSrlFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlightSrlFilterOptionsQuery({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetFlightSrlFilterOptionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetFlightSrlFilterOptionsQuery,
    GetFlightSrlFilterOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFlightSrlFilterOptionsQuery,
    GetFlightSrlFilterOptionsQueryVariables
  >(GetFlightSrlFilterOptionsDocument, options);
}
export function useGetFlightSrlFilterOptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFlightSrlFilterOptionsQuery,
    GetFlightSrlFilterOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFlightSrlFilterOptionsQuery,
    GetFlightSrlFilterOptionsQueryVariables
  >(GetFlightSrlFilterOptionsDocument, options);
}
export type GetFlightSrlFilterOptionsQueryHookResult = ReturnType<
  typeof useGetFlightSrlFilterOptionsQuery
>;
export type GetFlightSrlFilterOptionsLazyQueryHookResult = ReturnType<
  typeof useGetFlightSrlFilterOptionsLazyQuery
>;
export type GetFlightSrlFilterOptionsQueryResult = Apollo.QueryResult<
  GetFlightSrlFilterOptionsQuery,
  GetFlightSrlFilterOptionsQueryVariables
>;
