import { ApolloError } from '@apollo/client';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useRef } from 'react';
import { FlightType } from '@hotelplan/graphql.types';
import { useSearchState } from '@hotelplan/libs.search-state';
import { calculateNextPage } from '@hotelplan/libs.utils';
import { mapFormStateToFlightSearchControlCriteriaInput } from 'components/domain/flight/Flight.mappers';
import { IFlightSearchControlFormState } from 'components/domain/searchControl/SearchControl.types';
import { useGetFlightSrlOffersLazyQuery } from 'graphql/flightSRL/GetFlightSRLOffers.generated';
import { mapFlightSrlFormFilterValuesToFlightSrlFilterCriteriaInput } from './FlightSRL.mappers';
import type { IFlightSRLState } from './FlightSRL.types';
import { useFlightSRLSortingContext } from './FlightSRLSortingResultsProvider';
import { useFlightSearchTimestamps } from './useFligthSRLTimestamps';

const useGetFlightSRLOffers = (
  initialResultsPerPage: number,
  onError?: (error: ApolloError) => void
) => {
  const isRequested = useRef(false);
  const {
    loading: stateLoading,
    state: { searchControl, filters },
  } = useSearchState<IFlightSRLState>();

  const { sortingValue } = useFlightSRLSortingContext();
  const { canSearch, setFlightSearchTimestamp } = useFlightSearchTimestamps();

  // NOTE: This flag is used to determine that there was a search with searchInCacheOnly = false.
  const hadRealSearch = useRef<boolean | undefined>(undefined);

  const [
    getFlightSrlOffers,
    { data, loading, fetchMore },
  ] = useGetFlightSrlOffersLazyQuery({
    ssr: false,
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      if (canSearch) {
        setFlightSearchTimestamp();
      }
    },
    onError,
  });

  useEffect(() => {
    if (!stateLoading) {
      isRequested.current = true;
      getFlightSrlOffers({
        variables: {
          pageNumber: 0,
          resultsPerPage: initialResultsPerPage,
          sort: sortingValue,
          searchInCacheOnly: !canSearch,
          searchControl: mapFormStateToFlightSearchControlCriteriaInput(
            searchControl as IFlightSearchControlFormState
          ),
          filters: mapFlightSrlFormFilterValuesToFlightSrlFilterCriteriaInput(
            filters
          ),
        },
      });

      if (typeof hadRealSearch.current === 'undefined' && canSearch) {
        hadRealSearch.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchControl, filters, sortingValue, stateLoading]);

  const fetchMoreOffers = (nextPage: number | null): void => {
    if (nextPage === null) return;

    fetchMore({
      variables: {
        pageNumber: nextPage,
        resultsPerPage: initialResultsPerPage,
        sort: sortingValue,
        searchControl: mapFormStateToFlightSearchControlCriteriaInput(
          searchControl as IFlightSearchControlFormState
        ),
        filters: mapFlightSrlFormFilterValuesToFlightSrlFilterCriteriaInput(
          filters
        ),
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const finalOffersData = cloneDeep(fetchMoreResult);
        finalOffersData.flightSrl.content.flights.offers?.unshift(
          ...(prev.flightSrl.content.flights.offers || [])
        );
        return finalOffersData;
      },
    });
  };

  const nextPage = calculateNextPage(
    data?.flightSrl.content.flights.page.pageNumber || 0,
    data?.flightSrl.content.flights.page.resultsPerPage,
    data?.flightSrl.content.flights.page.resultsTotal
  );

  // NOTE: We should render destination only for not Open Jaw flights.
  const flight =
    searchControl?.flightType !== FlightType.OpenJaw &&
    searchControl?.flightPartitions
      ? searchControl.flightPartitions[0]
      : null;

  return {
    nextPage,
    isRequested: isRequested.current,
    onShowMore: fetchMoreOffers,
    loading: loading || stateLoading,
    hadRealSearch: hadRealSearch.current,
    data: data?.flightSrl.content.flights.offers || [],
    offersCount: data?.flightSrl.content.flights.count || 0,
    destination: flight ? flight.arrivalAirport?.name : null,
    cheapestPrice: data?.flightSrl.content.flights.cheapestPrice,
    isCacheEmpty: data ? data.flightSrl.content.flights.isCacheEmpty : true,
  };
};

export default useGetFlightSRLOffers;
