import { useField } from '@hotelplan/components.common.forms';
import { FlightType } from '@hotelplan/graphql.types';
import { FlightPartition } from 'components/domain/flightPartitions/FlightPartitions.types';
import { arePartitionsValid } from 'components/domain/flightPartitions/FlightPartitions.utils';

export const useFlightPartitions = (): {
  value: FlightPartition[] | null;
  areValid: boolean;
} => {
  const [activeType] = useField<FlightType | null>('flightType');
  const [flightPartitions] = useField<FlightPartition[] | null>(
    'flightPartitions'
  );

  return {
    value: flightPartitions,
    areValid: arePartitionsValid(flightPartitions, activeType),
  };
};
