import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Form, IFormApi, IFormProps } from '@hotelplan/components.common.forms';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import {
  useSearchState,
  useOnSearchStateChanges,
} from '@hotelplan/libs.search-state';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { mapMainFilterComponentValuesToFiltersFormState } from 'components/domain/filters/Filters.mappers';
import FiltersSkeleton from 'components/domain/filters/Filters.skeleton';
import type { IFiltersFormState } from 'components/domain/filters/Filters.types';
import useFiltersSubmitController from 'components/domain/filters/useFiltersSubmitController';
import type { MainFilterComponentValuesFragment } from 'graphql/searchFilter/MainFilterComponentValues.generated';
import type { IFlightSRLState } from './FlightSRL.types';
import useGetFlightSrlDefaultFilterValues from './useGetFlightSRLDefaultFilterValues';

interface IFlightSRLFiltersFormProps extends React.PropsWithChildren<{}> {
  postSubmit?: () => void;
  formApiRef?: React.RefObject<IFormApi<IFiltersFormState>>;
}

const FiltersSubmitController: React.FC<IFlightSRLFiltersFormProps> = ({
  children,
  postSubmit,
}) => {
  const stateContext = useSearchState<IFlightSRLState>();
  useFiltersSubmitController(stateContext, postSubmit);

  return <>{children}</>;
};

const FormWrapper = styled(Form)(
  sx2CssThemeFn({
    variant: 'forms.filtersForm',
    height: ['100%', 'auto'],
  })
) as React.FC<IFormProps<IFiltersFormState>>;

const FlightSRLFiltersForm: React.FC<IFlightSRLFiltersFormProps> = props => {
  const { mobile } = useDeviceType();
  const defaultFormApiRef = useRef<IFormApi<IFiltersFormState>>(null);
  const { postSubmit, formApiRef = defaultFormApiRef, children } = props;

  const {
    filters: defaultFilters,
    loading: defaultLoading,
  } = useGetFlightSrlDefaultFilterValues();

  const {
    loading,
    state: { searchControl, filters },
    setState,
  } = useSearchState<IFlightSRLState>();

  useEffect(() => {
    if (filters) {
      formApiRef?.current?.setValues(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOnSearchStateChanges<IFlightSRLState>(nextValues => {
    if (nextValues.filters) {
      formApiRef.current?.setValues(nextValues.filters);
    }
  });

  useEffect(
    function resetFiltersOnSearchControlChange() {
      if (filters) {
        formApiRef?.current?.setValues(filters);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchControl]
  );

  const onSubmit = (formState: IFiltersFormState): void => {
    setState(
      prev => ({
        ...prev,
        filters: mobile ? { ...formState, prevChangedFilter: null } : formState,
      }),
      postSubmit
    );
  };

  const onReset =
    !defaultLoading && defaultFilters
      ? (setValues: (defaultValues: IFiltersFormState) => void): void => {
          setValues(
            mapMainFilterComponentValuesToFiltersFormState(
              defaultFilters as MainFilterComponentValuesFragment
            )
          );
        }
      : undefined;

  if (loading || !filters) return <FiltersSkeleton />;

  return (
    <FormWrapper
      formApiRef={formApiRef}
      initialValues={filters}
      onSubmit={onSubmit}
      onReset={onReset}
    >
      {mobile ? (
        children
      ) : (
        <FiltersSubmitController {...props}>{children}</FiltersSubmitController>
      )}
    </FormWrapper>
  );
};

export default FlightSRLFiltersForm;
