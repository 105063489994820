import type { TFunction } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { useCurrentSearchContext } from 'components/domain/currentSearch/CurrentSearchContext';
import CustomLabel from 'components/domain/CustomLabel';
import FlightFormLayout from 'components/domain/FlightFormLayout';
import { useFlightPartitions } from 'components/domain/flightPartitions/useFlightPartitions';
import SearchControlFormLayout from 'components/domain/searchControl/SearchControlFormLayout';
import { useFourTravelRooms } from 'components/domain/travelRooms/useFourTravelRooms';
import FlightSRLAirportField from './FlightSRLAirportField';

const renderDepartureAirportField = (
  t: TFunction,
  partitionIndex: number
): React.ReactNode => {
  return (
    <FlightSRLAirportField
      name="departureAirport"
      parentItemIndex={partitionIndex}
      label={t('forms:departureAirport.placeholder')}
      placeholder={t('forms:departureAirport.placeholder')}
      customLabel={
        <CustomLabel htmlFor="departureAirport">
          {t('forms:departureAirport.label')}
        </CustomLabel>
      }
    />
  );
};

const renderArrivalAirportField = (
  t: TFunction,
  partitionIndex: number
): React.ReactNode => {
  return (
    <FlightSRLAirportField
      name="arrivalAirport"
      parentItemIndex={partitionIndex}
      label={t('forms:arrivalAirport.placeholder')}
      placeholder={t('forms:arrivalAirport.placeholder')}
      customLabel={
        <CustomLabel htmlFor="arrivalAirport">
          {t('forms:arrivalAirport.label')}
        </CustomLabel>
      }
    />
  );
};

const SearchControlFormLayoutWrapper = styled(SearchControlFormLayout)({
  backgroundColor: 'transparent',
  boxShadow: 'none',
});

const FlightSRLSearchControlFormContainer: React.FC<{
  handleCancel?: () => void;
  withCustomLabels?: boolean;
}> = ({ handleCancel }) => {
  const { ref } = useCurrentSearchContext();
  const { isValid: isTravelRoomsValid, isEmpty } = useFourTravelRooms();
  const { areValid: areFlightPartitionsValid } = useFlightPartitions();
  return (
    <div ref={ref}>
      <SearchControlFormLayoutWrapper>
        <FlightFormLayout
          renderArrivalAirportField={renderArrivalAirportField}
          renderDepartureAirportField={renderDepartureAirportField}
          isSubmitAllowed={
            areFlightPartitionsValid && isTravelRoomsValid && !isEmpty
          }
          handleCancel={handleCancel}
          withCustomLabels
        />
      </SearchControlFormLayoutWrapper>
    </div>
  );
};

export default FlightSRLSearchControlFormContainer;
