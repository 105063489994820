import { useFormContext } from '@hotelplan/components.common.forms';
import { FlightClass, FlightType } from '@hotelplan/graphql.types';
import { mergeFlightTypeToFlightSearchState } from 'components/domain/flightTypes/FlightTypes.mappers';
import { IFlightSearchControlFormState } from './SearchControl.types';

export enum FlightFormFieldTypes {
  TravelDatesField = 'travelDatesField',
  FlightClassField = 'flightClassField',
  FlightTypeField = 'flightTypeField',
}

export const useFlightFormContext = () => {
  const { values, onChange } = useFormContext<IFlightSearchControlFormState>();

  const onFlightFormChange = (
    nextValue: any,
    type: FlightFormFieldTypes,
    partitionIndex?: number
  ) => {
    switch (type) {
      case FlightFormFieldTypes.FlightClassField: {
        const flightPartitions = values.flightPartitions?.map(partition => {
          return {
            ...partition,
            flightClass: nextValue as FlightClass,
          };
        });

        onChange({
          ...values,
          flightPartitions,
        });

        break;
      }
      case FlightFormFieldTypes.FlightTypeField: {
        onChange(mergeFlightTypeToFlightSearchState(values, nextValue));

        break;
      }
      case FlightFormFieldTypes.TravelDatesField: {
        const activeType = values.flightType;
        const flightPartitions = values.flightPartitions;

        if (!nextValue || !flightPartitions) break;

        const secondPartitionDate =
          flightPartitions[1].travelDates?.departureDate;

        // NOTE: If the departure date of the first partition was selected later than it is in the second partition,
        // then we need to update the departure date of the second partition.
        if (
          activeType === FlightType.OpenJaw &&
          partitionIndex === 0 &&
          secondPartitionDate &&
          nextValue.departureDate > secondPartitionDate
        ) {
          onChange({
            ...values,
            flightPartitions: flightPartitions.map(partition => {
              return { ...partition, travelDates: nextValue };
            }),
          });
        }

        break;
      }
      default:
        break;
    }
  };

  return { values, onFlightFormChange };
};
