import camelCase from 'lodash/camelCase';
import { useTranslation } from 'next-i18next';
import React, { ReactNode, useMemo } from 'react';
import { getShortInformation as formatTravelRooms } from '@hotelplan/components.common.travel-rooms';
import { FlightType } from '@hotelplan/graphql.types';
import { useSearchState } from '@hotelplan/libs.search-state';
import CurrentSearch from 'components/domain/currentSearch/CurrentSearch';
import CurrentSearchSkeleton from 'components/domain/currentSearch/CurrentSearch.skeleton';
import { getShortInformation as formatFlightPartition } from 'components/domain/flightPartitions/FlightPartitions.utils';
import type { IFlightSRLState } from './FlightSRL.types';

type CurrentSearchItemType = {
  testId?: string;
  content: ReactNode;
};

const FlightSRLFormCurrentSearchField: React.FC<{ handleEdit: () => void }> = ({
  handleEdit,
}) => {
  const [t] = useTranslation('common');

  const {
    loading,
    state: { searchControl },
  } = useSearchState<IFlightSRLState>();

  const currentSearchItems = useMemo(() => {
    if (!searchControl) return [];

    const {
      flightPartitions = null,
      travelRooms = null,
      flightType = null,
    } = searchControl;

    const isReturnFlight = flightType === FlightType.Return;
    const partitionsToRender =
      flightType === FlightType.OpenJaw
        ? flightPartitions
        : flightPartitions?.slice(0, 1);

    const formattedTravelRooms =
      travelRooms && formatTravelRooms(t, travelRooms);
    const formattedFlightClass =
      partitionsToRender &&
      t(`flightClass.${camelCase(partitionsToRender[0].flightClass || '')}`);

    const formattedItems = [] as CurrentSearchItemType[];

    partitionsToRender?.map((partition, i) => {
      formattedItems.push({
        testId: `airportsFlight${i}`,
        content: formatFlightPartition(partition, isReturnFlight).airports,
      });
      formattedItems.push({
        testId: `travelDatesFlight${i}`,
        content: formatFlightPartition(partition, isReturnFlight).travelDates,
      });
    });

    return [
      ...formattedItems,
      {
        testId: 'travelRoomsAndFlightClass',
        content: `${formattedTravelRooms}, ${formattedFlightClass}`,
      },
    ];
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchControl]) as CurrentSearchItemType[];

  if (loading || !searchControl) return <CurrentSearchSkeleton />;

  return <CurrentSearch items={currentSearchItems} handleEdit={handleEdit} />;
};

export default FlightSRLFormCurrentSearchField;
