import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { DestinationFragmentDoc } from 'graphql/destination/Destination.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetFlightHomeAirportAutocompleteQueryVariables = Types.Exact<{
  text: Types.Scalars['String'];
  selected?: Types.InputMaybe<
    Array<Types.Scalars['String']> | Types.Scalars['String']
  >;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetFlightHomeAirportAutocompleteQuery = {
  __typename?: 'Query';
  flightHome: {
    __typename?: 'FlightHomeContext';
    autocomplete: {
      __typename?: 'AutocompleteComponent';
      page: {
        __typename?: 'Page';
        pageNumber: number;
        resultsTotal?: number | null;
        resultsPerPage?: number | null;
      };
      destinations: Array<{
        __typename?: 'TravelDestination';
        id: string;
        name: string;
        type: Types.TravelDestinationType;
        mythosCode?: string | null;
        location?: {
          __typename?: 'GeoLocation';
          airport?: {
            __typename?: 'Airport';
            iata?: string | null;
            name?: string | null;
          } | null;
          continent?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
          countryGroup?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
          country?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
          region?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
          destination?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
          resort?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
        } | null;
      }>;
    };
  };
};

export type GetFlightSrlAirportAutocompleteQueryVariables = Types.Exact<{
  text: Types.Scalars['String'];
  selected?: Types.InputMaybe<
    Array<Types.Scalars['String']> | Types.Scalars['String']
  >;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetFlightSrlAirportAutocompleteQuery = {
  __typename?: 'Query';
  flightSrl: {
    __typename?: 'FlightSrlContext';
    autocomplete: {
      __typename?: 'AutocompleteComponent';
      page: {
        __typename?: 'Page';
        pageNumber: number;
        resultsTotal?: number | null;
        resultsPerPage?: number | null;
      };
      destinations: Array<{
        __typename?: 'TravelDestination';
        id: string;
        name: string;
        type: Types.TravelDestinationType;
        mythosCode?: string | null;
        location?: {
          __typename?: 'GeoLocation';
          airport?: {
            __typename?: 'Airport';
            iata?: string | null;
            name?: string | null;
          } | null;
          continent?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
          countryGroup?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
          country?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
          region?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
          destination?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
          resort?: {
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          } | null;
        } | null;
      }>;
    };
  };
};

export const GetFlightHomeAirportAutocompleteDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFlightHomeAirportAutocomplete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'selected' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '0' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightHome' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'autocomplete' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pageNumber' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'page' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'resultsPerPage',
                                  },
                                  value: { kind: 'IntValue', value: '5' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'selected' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'selected' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'input' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'text' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'page' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resultsTotal' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resultsPerPage' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'destinations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'destination' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...DestinationFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetFlightHomeAirportAutocompleteQuery__
 *
 * To run a query within a React component, call `useGetFlightHomeAirportAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlightHomeAirportAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlightHomeAirportAutocompleteQuery({
 *   variables: {
 *      text: // value for 'text'
 *      selected: // value for 'selected'
 *      page: // value for 'page'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetFlightHomeAirportAutocompleteQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFlightHomeAirportAutocompleteQuery,
    GetFlightHomeAirportAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFlightHomeAirportAutocompleteQuery,
    GetFlightHomeAirportAutocompleteQueryVariables
  >(GetFlightHomeAirportAutocompleteDocument, options);
}
export function useGetFlightHomeAirportAutocompleteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFlightHomeAirportAutocompleteQuery,
    GetFlightHomeAirportAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFlightHomeAirportAutocompleteQuery,
    GetFlightHomeAirportAutocompleteQueryVariables
  >(GetFlightHomeAirportAutocompleteDocument, options);
}
export type GetFlightHomeAirportAutocompleteQueryHookResult = ReturnType<
  typeof useGetFlightHomeAirportAutocompleteQuery
>;
export type GetFlightHomeAirportAutocompleteLazyQueryHookResult = ReturnType<
  typeof useGetFlightHomeAirportAutocompleteLazyQuery
>;
export type GetFlightHomeAirportAutocompleteQueryResult = Apollo.QueryResult<
  GetFlightHomeAirportAutocompleteQuery,
  GetFlightHomeAirportAutocompleteQueryVariables
>;
export const GetFlightSrlAirportAutocompleteDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFlightSRLAirportAutocomplete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'selected' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '0' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightSrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'autocomplete' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pageNumber' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'page' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'resultsPerPage',
                                  },
                                  value: { kind: 'IntValue', value: '5' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'selected' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'selected' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'input' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'text' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'page' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resultsTotal' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resultsPerPage' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'destinations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'destination' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...DestinationFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetFlightSrlAirportAutocompleteQuery__
 *
 * To run a query within a React component, call `useGetFlightSrlAirportAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlightSrlAirportAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlightSrlAirportAutocompleteQuery({
 *   variables: {
 *      text: // value for 'text'
 *      selected: // value for 'selected'
 *      page: // value for 'page'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetFlightSrlAirportAutocompleteQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFlightSrlAirportAutocompleteQuery,
    GetFlightSrlAirportAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFlightSrlAirportAutocompleteQuery,
    GetFlightSrlAirportAutocompleteQueryVariables
  >(GetFlightSrlAirportAutocompleteDocument, options);
}
export function useGetFlightSrlAirportAutocompleteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFlightSrlAirportAutocompleteQuery,
    GetFlightSrlAirportAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFlightSrlAirportAutocompleteQuery,
    GetFlightSrlAirportAutocompleteQueryVariables
  >(GetFlightSrlAirportAutocompleteDocument, options);
}
export type GetFlightSrlAirportAutocompleteQueryHookResult = ReturnType<
  typeof useGetFlightSrlAirportAutocompleteQuery
>;
export type GetFlightSrlAirportAutocompleteLazyQueryHookResult = ReturnType<
  typeof useGetFlightSrlAirportAutocompleteLazyQuery
>;
export type GetFlightSrlAirportAutocompleteQueryResult = Apollo.QueryResult<
  GetFlightSrlAirportAutocompleteQuery,
  GetFlightSrlAirportAutocompleteQueryVariables
>;
