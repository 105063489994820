import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { RadioButton } from '@hotelplan/components.common.buttons';
import { withActiveStyles } from '@hotelplan/components.common.mixins';
import { FlightType } from '@hotelplan/graphql.types';
import { sx2CssTheme, sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { ENTER_KEY } from 'components/domain/keyboard-navigation/keyboard-navigation.constant';
import {
  FlightFormFieldTypes,
  useFlightFormContext,
} from 'components/domain/searchControl/useFlightFormContext';

const flightTypesMap: Array<{
  type: FlightType;
  label: string;
  testId?: string;
}> = [
  {
    type: FlightType.Return,
    label: 'forms:returnFlight.radioInput',
  },
  {
    type: FlightType.OneWay,
    label: 'forms:oneWayFlight.radioInput',
  },
  {
    type: FlightType.OpenJaw,
    label: 'forms:openJawFlight.radioInput',
  },
];

const TextWrapper = styled.span<{ checked: boolean }>(
  ({ checked, theme, theme: { colors } }) =>
    withActiveStyles(
      checked,
      sx2CssTheme(theme, {
        fontSize: [1, 2],
        px: ['10px', '27px'],
        py: [1, 2],
        border: `1px solid ${colors.primary}`,
        color: colors.primary,
        cursor: 'pointer',
        borderRadius: ['15px', '20px'],
        lineHeight: [null, '1.4'],
        '&:hover,&:focus': {
          color: colors.interactionPrimary,
          backgroundColor: colors.lightMint,
          borderColor: colors.interactionPrimary,
          outline: 'none',
        },
      }),
      {
        backgroundColor: colors.primary,
        color: colors.white,
        '&:hover': {
          color: colors.white,
          backgroundColor: colors.primary,
          borderColor: colors.primary,
          outline: 'none',
        },
        '&:focus': {
          color: colors.white,
          backgroundColor: colors.interactionPrimary,
          borderColor: colors.interactionPrimary,
        },
      }
    )
);

const PillsRadioInput: React.FC<{
  text: string;
  checked?: boolean;
  onKeyPress(e: React.KeyboardEvent<HTMLInputElement>): void;
}> = ({ text, onKeyPress, checked = false }) => {
  return (
    <TextWrapper
      onKeyPress={onKeyPress}
      tabIndex={0}
      checked={checked}
      className="pills-radio-input"
    >
      {text}
    </TextWrapper>
  );
};

interface IFlightTypeRadioButton {
  flightType: FlightType;
  checked: boolean;
  label: string;
  testId?: string;
  onChange(value: FlightType): void;
  onKeyPress(e: React.KeyboardEvent<HTMLInputElement>): void;
}

const FlightTypeRadioInput = styled(RadioButton)(({ theme: { colors } }) => ({
  // NOTE: Try to keep radio buttons labels in one line as much as possible, on the smaller screens the text is longer
  // than enough space to keep it in one line
  '@media screen and (max-width: 370px)': {
    flex: '0 0 auto',
  },
  '&:focus-visible': {
    span: {
      outline: `auto 2px`,
      outlineOffset: '-1px',
      outlineColor: colors.defaultText,
    },
  },
  '.pills-radio-input': {
    '&:focus-visible': {
      span: {
        outline: `auto 2px`,
        outlineOffset: '-1px',
        outlineColor: colors.defaultText,
      },
    },
  },
}));

const FlightTypeRadioButton: React.FC<IFlightTypeRadioButton> = ({
  flightType,
  checked,
  label,
  testId,
  onChange,
  onKeyPress,
}) => {
  return (
    <FlightTypeRadioInput
      tabIndex={-1}
      hideLabel
      id={flightType}
      className="flight-type-label"
      checked={checked}
      name="flightTypes"
      value={flightType}
      testId={testId}
      onChange={(value: string): void => onChange(value as FlightType)}
      renderCustomControl={() => (
        <PillsRadioInput
          checked={checked}
          text={label}
          onKeyPress={onKeyPress}
        />
      )}
    />
  );
};

const FlightTypesWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexWrap: ['wrap', 'nowrap'],
    m: ['-13px', 0],
    mt: 0,
    alignItems: 'flex-start',
    '.label': {
      m: ['13px', 0],
      mr: [0, '20px'],
      mb: ['13px', 0],
      mt: 0,
    },
  })
);

const FlightTypesField: React.FC = () => {
  const [t] = useTranslation('forms');
  const { values, onFlightFormChange } = useFlightFormContext();

  return (
    <FlightTypesWrapper>
      {flightTypesMap.map(flightType => (
        <FlightTypeRadioButton
          key={flightType.type}
          label={t(flightType.label)}
          flightType={flightType.type}
          checked={values.flightType === flightType.type}
          onChange={(nextFlightType): void => {
            onFlightFormChange(
              nextFlightType,
              FlightFormFieldTypes.FlightTypeField
            );
          }}
          onKeyPress={e => {
            if (e.key === ENTER_KEY) {
              onFlightFormChange(
                flightType.type,
                FlightFormFieldTypes.FlightTypeField
              );
            }
          }}
        />
      ))}
    </FlightTypesWrapper>
  );
};

export default FlightTypesField;
