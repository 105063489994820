import { useGetFlightSrlDefaultFilterValuesQuery } from 'graphql/flightSRL/GetFlightSRLDefaultFilterValues.generated';

export default function useGetFlightSrlDefaultFilterValues() {
  const { data, loading } = useGetFlightSrlDefaultFilterValuesQuery();

  return {
    filters: data?.flightSrl.filters,
    loading,
  };
}
