import { DocumentNode } from 'graphql';
import React, { ReactElement } from 'react';
import {
  getNextPage,
  AutocompleteDataSource,
} from '@hotelplan/components.common.autocomplete';
import { Page } from '@hotelplan/graphql.types';
import {
  trackFlightAirports,
  FlightAirportsEventType,
} from '@hotelplan/libs.tracking';
import {
  mapCanonicalAirportToIDs,
  mapTravelDestinationObjectToCanonicalObject,
  mapCanonicalFlightAirportToTrackableFlightAirport,
} from 'components/domain/flightAirport/FlightAirport.mappers';
import {
  IAirportAutocompleteVariables,
  ICanonicalAirport,
} from 'components/domain/flightAirport/FlightAirport.types';
import FlightAirportField from 'components/domain/flightAirport/FlightAirportField';
import { DestinationFragment } from 'graphql/destination/Destination.generated';

interface IAirportData {
  page: Page | undefined;
  destinations: DestinationFragment[];
}

interface IGenericFlightAirportProps<TData, TVariables> {
  name: string;
  label: string;
  disabled?: boolean;
  placeholder: string;
  parentItemIndex?: number;
  parentItemName?: string;
  queryDocument: DocumentNode;
  dataMapper: (data: TData | undefined) => IAirportData | undefined;
  variablesMapper: (commonVars: IAirportAutocompleteVariables) => TVariables;
  customLabel?: React.ReactNode;
}

const trackFlightAirportsSearchResult = (
  data: IAirportData | undefined,
  text: string
) => {
  const airports = (data?.destinations
    ?.map(mapTravelDestinationObjectToCanonicalObject)
    .filter(Boolean) || []) as ICanonicalAirport[];
  const currentPage = data?.page?.pageNumber || 0;
  const nextPage = getNextPage<ICanonicalAirport>(airports, data?.page);
  const first = currentPage === 0;
  const hasMore = nextPage !== 0;

  trackFlightAirports({
    type: FlightAirportsEventType.FLIGHT_AIRPORTS_RESULTS,
    payload: {
      airports: airports.map(mapCanonicalFlightAirportToTrackableFlightAirport),
      first,
      hasMore,
      text,
    },
  });
};

const GenericFlightAirportField = <
  TData extends object,
  TVariables extends { text: string }
>(
  props: React.PropsWithChildren<IGenericFlightAirportProps<TData, TVariables>>
): ReactElement => {
  const {
    name,
    label,
    placeholder,
    parentItemIndex,
    parentItemName,
    queryDocument,
    disabled,
    dataMapper,
    variablesMapper,
    customLabel,
  } = props;

  return (
    <FlightAirportField
      name={name}
      label={label}
      disabled={disabled}
      placeholder={placeholder}
      parentItemIndex={parentItemIndex}
      parentItemName={parentItemName}
      customLabel={customLabel}
    >
      <AutocompleteDataSource<
        ICanonicalAirport,
        TData,
        TVariables,
        DestinationFragment
      >
        queryDocument={queryDocument}
        dataMapper={data =>
          data
            ? {
                items: (dataMapper(data as TData) as IAirportData).destinations,
                page: (dataMapper(data as TData) as IAirportData).page,
              }
            : undefined
        }
        variablesMapper={variables =>
          variablesMapper({
            text: variables.query,
            page: variables.page || 0,
            selected: variables.selected.map(mapCanonicalAirportToIDs),
          })
        }
        suggestionMapper={mapTravelDestinationObjectToCanonicalObject}
        onCompleted={(x, currentVariables) => {
          trackFlightAirportsSearchResult(dataMapper(x), currentVariables.text);
        }}
      />
    </FlightAirportField>
  );
};

export default GenericFlightAirportField;
