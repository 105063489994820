import React from 'react';
import styled from 'styled-components';
import { useSearchState } from '@hotelplan/libs.search-state';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import type { IFiltersFormState } from 'components/domain/filters/Filters.types';
import FilterTags from 'components/domain/filters/FilterTags';
import FilterTagsSkeleton from 'components/domain/tag/TagBox.skeleton';
import type { IFlightSRLState } from './FlightSRL.types';
import useFlightSRLFilterOptions from './useGetFlightSRLFilterOptions';

const FilterTagsSkeletonWrapper = styled(FilterTagsSkeleton).attrs({
  className: 'filter-tags-skeleton',
})(
  sx2CssThemeFn({
    '.filter-tags-skeleton': {
      p: 0,
      px: 4,
      mb: 3,
      border: 'none',
    },
  })
);

const FilterTagsWrapper = styled(FilterTags).attrs({
  className: 'filter-tags',
})(
  sx2CssThemeFn({
    '.filter-tags': {
      p: 0,
      px: 4,
      mb: 3,
      border: 'none',
    },
  })
);

const FlightSRLFiltersOverview: React.FC = () => {
  const {
    loading,
    state: { filters },
    setState,
  } = useSearchState<IFlightSRLState>();

  const {
    options: filterOptions,
    loading: optionsLoading,
  } = useFlightSRLFilterOptions();

  const onChange = (nextFilters: IFiltersFormState | null): void => {
    setState(prev => ({
      ...prev,
      filters: nextFilters || {},
    }));
  };

  if (loading || optionsLoading) return <FilterTagsSkeletonWrapper />;

  if (!filters || !filterOptions) return null;

  return (
    <FilterTagsWrapper
      lines={3}
      filters={filters}
      options={filterOptions}
      onChange={onChange}
    />
  );
};

export default FlightSRLFiltersOverview;
