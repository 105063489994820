import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { PriceFragmentFragmentDoc } from 'graphql/price/Price.generated';
import { FlightSrlOfferItemFragmentDoc } from './FlightSRLOfferItem.generated';
export type FlightSrlFragment = {
  __typename?: 'FlightSrlComponent';
  count?: number | null;
  isCacheEmpty?: boolean | null;
  cheapestPrice: { __typename?: 'Price'; amount: string; currency: string };
  offers: Array<{
    __typename?: 'FlightOffer';
    offerId: string;
    waitingScreenImage: string;
    pricePerPerson: { __typename?: 'Price'; amount: string; currency: string };
    totalPrice: { __typename?: 'Price'; amount: string; currency: string };
    forwardFlight: {
      __typename?: 'Flight';
      departureTime: any;
      arrivalTime: any;
      duration: string;
      arrivalDate: any;
      departureDate: any;
      overnight: boolean;
      departure: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      arrival: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      segments: Array<{
        __typename?: 'FlightSegment';
        airportWaiting?: string | null;
        arrivalDate: any;
        arrivalTime: any;
        departureDate: any;
        departureTime: any;
        flightClass: Types.FlightClass;
        flightNumber: string;
        segmentDuration: string;
        airline: {
          __typename?: 'Airline';
          airlineLogo: string;
          iata: string;
          name: string;
        };
        arrival: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
        departure: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
      }>;
    };
    returnFlight?: {
      __typename?: 'Flight';
      departureTime: any;
      arrivalTime: any;
      duration: string;
      arrivalDate: any;
      departureDate: any;
      overnight: boolean;
      departure: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      arrival: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      };
      segments: Array<{
        __typename?: 'FlightSegment';
        airportWaiting?: string | null;
        arrivalDate: any;
        arrivalTime: any;
        departureDate: any;
        departureTime: any;
        flightClass: Types.FlightClass;
        flightNumber: string;
        segmentDuration: string;
        airline: {
          __typename?: 'Airline';
          airlineLogo: string;
          iata: string;
          name: string;
        };
        arrival: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
        departure: {
          __typename?: 'Airport';
          iata?: string | null;
          name?: string | null;
        };
      }>;
    } | null;
  }>;
  page: {
    __typename?: 'Page';
    pageNumber: number;
    resultsPerPage?: number | null;
    resultsTotal?: number | null;
  };
};

export const FlightSrlFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlightSRL' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FlightSrlComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cheapestPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PriceFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCacheEmpty' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FlightSRLOfferItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'page' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pageNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resultsPerPage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resultsTotal' },
                },
              ],
            },
          },
        ],
      },
    },
    ...PriceFragmentFragmentDoc.definitions,
    ...FlightSrlOfferItemFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
