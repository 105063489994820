import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { Link } from '@hotelplan/components.common.link';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { Flight, Price } from '@hotelplan/graphql.types';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { formatPrice } from '@hotelplan/libs.utils';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import FlightSection from 'components/domain/flightSection/FlightSection';
import { mapOrlFlightToIFlightSectionProps } from 'components/domain/orl/ORL.mappers';
import FlightDetails from './FlightDetails';

export interface IFlightItemProps {
  pricePerPerson: Price;
  totalPrice: Price;
  flight?: Flight | null;
  returnFlight?: Flight | null;
  onCheckout?: () => void;
}

const FlightOfferItemWrapper = styled.li.attrs({
  'data-id': `flight-offer-item`,
})(
  sx2CssThemeFn({
    backgroundColor: 'white',
    mb: 4,
    borderRadius: 'default',
    overflow: 'hidden',
    boxShadow: 'cardShadow',
    listStyle: 'none',
  })
);

const FlightContainer = styled.div(({ theme: { radii, colors } }) => ({
  border: '1px solid',
  borderColor: colors.borderColor,
  borderBottom: 'none',
  borderRadius: radii.roundedTop,
}));

const FlightHeaderCard = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    borderBottom: '1px solid',
    borderColor: 'lightGreyFourth',
    alignItems: 'center',
    fontSize: [null, 3],
    height: ['37px', '44px'],
  })
);

const FlightDetailsButton = styled(BsButton)(
  sx2CssThemeFn({
    ml: 'auto',
    fontSize: [1, 2],
    mr: [3, 4],
  })
);

const FlightHeaderCardDetailsWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flex: '1',
    paddingLeft: '20px',
    pr: [3, 4],
    '.flight-details-close-button': {
      color: 'inherit',
      '&:hover, &:focus': {
        color: [null, 'primary'],
      },
      '.icon': {
        variant: ['icons.sm', 'icons.md'],
      },
      ml: 'auto',
    },
  })
);

const FlightHeaderCardDetails = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    alignItems: 'center',
    '.flight-card-details-icon': {
      mr: [2, 3],
      variant: ['icons.md', 'icons.xlg'],
    },
    '.flight-card-details-text': {
      fontWeight: 'bold',
      fontSize: ['18px', '25px'],
    },
  })
);

const FlightOfferWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    '.flight-departure-offer': {
      px: [2, '30px'],
      pt: ['12px', '20px'],
      pb: ['12px', 3],
      borderBottom: '1px solid',
      borderColor: 'lightGreyFourth',
    },
    '.flight-arrival-offer': {
      px: [2, '30px'],
      pt: ['12px', '20px'],
      pb: ['12px', 3],
    },
  })
);

const FlightArrivalHeader = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: 'lightGreyFourth',
    alignItems: 'center',
    pl: '20px',
    py: [1, 2],
    '.flight-arrival-icon': {
      mr: [2, 3],
      variant: ['icons.md', 'icons.xlg'],
    },
    '.flight-arrival-text': {
      fontWeight: 'bold',
      fontSize: ['18px', '25px'],
    },
  })
);

const PriceLink = styled(Link)(
  sx2CssThemeFn({
    variant: 'link.priceLink',
    letterSpacing: [null, '1.5'],
    py: [null, '9px'],
    px: [null, '20px'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '> .icon': {
      my: ['3px', 1],
      width: '7px',
      height: '13px',
    },
    '.price-link-icon': {
      variant: 'icons.sm',
      verticalAlign: [null, `super`],
    },
  })
);

const PriceLinkTextWrapper = styled.div(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    marginRight: [2, 3],
    paddingBottom: 1,
    '.offer-total-price': {
      ...FONT_SIZE.L,
      width: '100%',
    },
    '.offer-price-per-person': {
      ...FONT_SIZE.XS,
      marginRight: [null, 1],
    },
  })
);

const FlightItem: React.FC<IFlightItemProps> = ({
  flight,
  returnFlight,
  pricePerPerson,
  totalPrice,
  onCheckout,
}) => {
  const [t] = useTranslation('frl');
  const [isDetailsOpen, openDetailsOpen, closeDetailsOpen] = useToggleState(
    false
  );

  return (
    <FlightOfferItemWrapper>
      <FlightContainer>
        <FlightHeaderCard>
          {!isDetailsOpen && (
            <FlightDetailsButton
              data-id="details-btn"
              variant={E_BUTTON_TYPE.LINK_BUTTON}
              onClick={openDetailsOpen}
            >
              {t('flight.details')}
            </FlightDetailsButton>
          )}
          {isDetailsOpen && (
            <FlightHeaderCardDetailsWrapper>
              <FlightHeaderCardDetails>
                <Icon name="flug" className="flight-card-details-icon" />
                <div className="flight-card-details-text">
                  {t('flight.outward')}
                </div>
              </FlightHeaderCardDetails>
              <BsButtonWithIcon
                variant={E_BUTTON_TYPE.ICON_BUTTON}
                data-id="closeModal"
                icon={{ name: 'close' }}
                className="flight-details-close-button"
                onClick={closeDetailsOpen}
              />
            </FlightHeaderCardDetailsWrapper>
          )}
        </FlightHeaderCard>
        {!isDetailsOpen && (
          <FlightOfferWrapper>
            {flight && (
              <FlightSection
                displayFlightDetails
                className="flight-departure-offer"
                {...mapOrlFlightToIFlightSectionProps(flight)}
              />
            )}
            {returnFlight && (
              <FlightSection
                isReturn
                displayFlightDetails
                className="flight-arrival-offer"
                {...mapOrlFlightToIFlightSectionProps(returnFlight)}
              />
            )}
          </FlightOfferWrapper>
        )}
        {isDetailsOpen && (
          <>
            <FlightDetails testId="forward-flight-details" flight={flight} />
            {returnFlight && (
              <>
                <FlightArrivalHeader>
                  <Icon name="flug-departure" className="flight-arrival-icon" />
                  <div className="flight-arrival-text">
                    {t('flight.return')}
                  </div>
                </FlightArrivalHeader>
                <FlightDetails
                  testId="return-flight-details"
                  flight={returnFlight}
                />
              </>
            )}
          </>
        )}
      </FlightContainer>
      <PriceLink
        href="#"
        onClick={e => {
          e.preventDefault();
          onCheckout?.();
        }}
      >
        <PriceLinkTextWrapper>
          <span className="offer-total-price" data-id="offer-total-price">
            {formatPrice(totalPrice)}
          </span>
          <span
            className="offer-price-per-person"
            data-id="offer-price-per-person"
          >
            {t('common:perPerson')} {formatPrice(pricePerPerson)}
          </span>
        </PriceLinkTextWrapper>
        <Icon name="chevron-left" className="price-link-icon" />
      </PriceLink>
    </FlightOfferItemWrapper>
  );
};

export default FlightItem;
