import React from 'react';
import { useFormContext } from '@hotelplan/components.common.forms';
import { useSearchState } from '@hotelplan/libs.search-state';
import { flightFiltersOrder } from 'components/domain/filters/Filters.constants';
import type {
  IFiltersFormState,
  IFilterOptions,
  TFilterOptionCaptions,
} from 'components/domain/filters/Filters.types';
import SearchFilterInput from 'components/domain/filters/search-filter-input/SearchFilterInput';
import { SearchFilterInputSkeleton } from 'components/domain/filters/search-filter-input/SearchFilterInput.skeleton';
import { arePartitionsValid } from 'components/domain/flightPartitions/FlightPartitions.utils';
import type { IFlightSRLState } from './FlightSRL.types';
import FlightSRLFilterCountsLoader from './FlightSRLFilterCountsLoader';
import useFlightSRLFilterOptions from './useGetFlightSRLFilterOptions';

const FlightSRLSearchFilterField: React.FC<{
  onCloseModal?: () => void;
}> = ({ onCloseModal }) => {
  const {
    values: filterValues,
    onChange,
    onReset,
  } = useFormContext<IFiltersFormState>();

  const {
    state: { searchControl },
  } = useSearchState<IFlightSRLState>();

  const areFlightPartitionsValid = arePartitionsValid(
    searchControl?.flightPartitions,
    searchControl?.flightType
  );

  // NOTE: Departure & Arrival times filter depends on airports, therefore need to check that they were filled.
  const filtersOrder = areFlightPartitionsValid
    ? flightFiltersOrder
    : flightFiltersOrder.filter(
        filter => filter !== 'flightDepartureArrivalTimes'
      );

  const { options, optionCaptions, loading } = useFlightSRLFilterOptions();

  if (loading && !options) return <SearchFilterInputSkeleton />;

  return (
    <SearchFilterInput
      onReset={onReset}
      filters={filtersOrder}
      onChange={onChange}
      filterValues={filterValues}
      onCloseModal={onCloseModal}
      filterCountsLoader={FlightSRLFilterCountsLoader}
      filterOptions={options as IFilterOptions}
      filterOptionCaptions={optionCaptions as TFilterOptionCaptions}
    />
  );
};

export default FlightSRLSearchFilterField;
