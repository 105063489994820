import React, { useRef } from 'react';
import type { IFormApi } from '@hotelplan/components.common.forms';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import type { IFiltersFormState } from 'components/domain/filters/Filters.types';
import FiltersButton from 'components/domain/filters/FiltersButton';
import FiltersModal from 'components/domain/filters/FiltersModal';
import FlightSRLSearchFilterField from './FlightSRLFilterField';
import FlightSRLFiltersForm from './FlightSRLFiltersForm';
import { useFlightSearchTimestamps } from './useFligthSRLTimestamps';

const FlightSRLMobileFilters: React.FC = () => {
  const [isModalShowed, openModal, closeModal] = useToggleState(false);
  const { setFlightSearchRequestTimestamp } = useFlightSearchTimestamps();
  const formApiRef = useRef<IFormApi<IFiltersFormState>>(null);
  return (
    <>
      <FiltersButton onClick={openModal} />
      <FiltersModal onClose={closeModal} isModalShowed={isModalShowed}>
        <FlightSRLFiltersForm
          formApiRef={formApiRef}
          postSubmit={() => {
            closeModal();
            setFlightSearchRequestTimestamp();
          }}
        >
          <FlightSRLSearchFilterField onCloseModal={closeModal} />
        </FlightSRLFiltersForm>
      </FiltersModal>
    </>
  );
};

export default FlightSRLMobileFilters;
