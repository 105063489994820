import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { FlightSrlFragmentDoc } from './FlightSRL.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetFlightSrlOffersQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int'];
  resultsPerPage: Types.Scalars['Int'];
  sort: Types.FlightSortField;
  filters?: Types.InputMaybe<Types.FlightSrlFilterCriteriaInput>;
  searchInCacheOnly?: Types.InputMaybe<Types.Scalars['Boolean']>;
  searchControl: Types.FlightSearchControlComponentInput;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetFlightSrlOffersQuery = {
  __typename?: 'Query';
  flightSrl: {
    __typename?: 'FlightSrlContext';
    content: {
      __typename?: 'FlightSrlContainer';
      flights: {
        __typename?: 'FlightSrlComponent';
        count?: number | null;
        isCacheEmpty?: boolean | null;
        cheapestPrice: {
          __typename?: 'Price';
          amount: string;
          currency: string;
        };
        offers: Array<{
          __typename?: 'FlightOffer';
          offerId: string;
          waitingScreenImage: string;
          pricePerPerson: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          };
          totalPrice: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          };
          forwardFlight: {
            __typename?: 'Flight';
            departureTime: any;
            arrivalTime: any;
            duration: string;
            arrivalDate: any;
            departureDate: any;
            overnight: boolean;
            departure: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            arrival: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            segments: Array<{
              __typename?: 'FlightSegment';
              airportWaiting?: string | null;
              arrivalDate: any;
              arrivalTime: any;
              departureDate: any;
              departureTime: any;
              flightClass: Types.FlightClass;
              flightNumber: string;
              segmentDuration: string;
              airline: {
                __typename?: 'Airline';
                airlineLogo: string;
                iata: string;
                name: string;
              };
              arrival: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
              departure: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
            }>;
          };
          returnFlight?: {
            __typename?: 'Flight';
            departureTime: any;
            arrivalTime: any;
            duration: string;
            arrivalDate: any;
            departureDate: any;
            overnight: boolean;
            departure: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            arrival: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            };
            segments: Array<{
              __typename?: 'FlightSegment';
              airportWaiting?: string | null;
              arrivalDate: any;
              arrivalTime: any;
              departureDate: any;
              departureTime: any;
              flightClass: Types.FlightClass;
              flightNumber: string;
              segmentDuration: string;
              airline: {
                __typename?: 'Airline';
                airlineLogo: string;
                iata: string;
                name: string;
              };
              arrival: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
              departure: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              };
            }>;
          } | null;
        }>;
        page: {
          __typename?: 'Page';
          pageNumber: number;
          resultsPerPage?: number | null;
          resultsTotal?: number | null;
        };
      };
    };
  };
};

export const GetFlightSrlOffersDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFlightSRLOffers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pageNumber' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resultsPerPage' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FlightSortField' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'FlightSrlFilterCriteriaInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchInCacheOnly' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchControl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'FlightSearchControlComponentInput',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightSrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flights' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {
                              kind: 'Name',
                              value: 'flightSrlSearchCriteria',
                            },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'page' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'pageNumber',
                                        },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'pageNumber',
                                          },
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'resultsPerPage',
                                        },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'resultsPerPage',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'sort' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'field' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'sort' },
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'searchInCacheOnly',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'searchInCacheOnly',
                                    },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'filter' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'searchControl',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'searchControl',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'FlightSRL' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FlightSrlFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetFlightSrlOffersQuery__
 *
 * To run a query within a React component, call `useGetFlightSrlOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlightSrlOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlightSrlOffersQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      resultsPerPage: // value for 'resultsPerPage'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      searchInCacheOnly: // value for 'searchInCacheOnly'
 *      searchControl: // value for 'searchControl'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetFlightSrlOffersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFlightSrlOffersQuery,
    GetFlightSrlOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFlightSrlOffersQuery,
    GetFlightSrlOffersQueryVariables
  >(GetFlightSrlOffersDocument, options);
}
export function useGetFlightSrlOffersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFlightSrlOffersQuery,
    GetFlightSrlOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFlightSrlOffersQuery,
    GetFlightSrlOffersQueryVariables
  >(GetFlightSrlOffersDocument, options);
}
export type GetFlightSrlOffersQueryHookResult = ReturnType<
  typeof useGetFlightSrlOffersQuery
>;
export type GetFlightSrlOffersLazyQueryHookResult = ReturnType<
  typeof useGetFlightSrlOffersLazyQuery
>;
export type GetFlightSrlOffersQueryResult = Apollo.QueryResult<
  GetFlightSrlOffersQuery,
  GetFlightSrlOffersQueryVariables
>;
