import type { TFunction } from 'next-i18next';
import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { useField } from '@hotelplan/components.common.forms';
import { FlightType } from '@hotelplan/graphql.types';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import CustomLabel from 'components/domain/CustomLabel';
import FlightTravelDatesField from 'components/domain/flight-travel-dates/FlightTravelDatesField';
import { useFlightPartitions } from 'components/domain/flightPartitions/useFlightPartitions';

interface IFlightPartitions {
  renderDepartureAirportField: (
    t: TFunction,
    partitionId: number,
    withCustomLabels?: boolean
  ) => React.ReactNode;
  renderArrivalAirportField: (
    t: TFunction,
    partitionId: number,
    withCustomLabels?: boolean
  ) => React.ReactNode;
  withCustomLabels?: boolean;
}

const FlightPartition = styled.div.attrs({
  'data-id': 'flightPartition',
})(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    '.partition-title': {
      color: 'secondary',
      ...FONT_SIZE.S,
      mb: [3, 4],
      display: 'flex',
      alignItems: 'center',
      '&:after': {
        content: '""',
        display: 'block',
        flex: '1 auto',
        height: '1px',
        backgroundColor: 'secondary',
        ml: 3,
        mt: [null, 2],
      },
    },
  })
);

const FlightPartitionsWrapper = styled.div<{
  shouldShowPartitionHeadings: boolean;
}>(({ shouldShowPartitionHeadings }) =>
  sx2CssThemeFn({
    display: 'flex',
    flexWrap: ['wrap', 'nowrap'],
    mb: shouldShowPartitionHeadings ? [null, 5] : {},
    '.airport-field, .flight-travel-dates-field': {
      flex: ['100%', '1', '1 1 405px'],
      mb: [2, 2, 0],
      '&:not(:last-child)': {
        mr: [0, '10px'],
      },
    },
    '.flight-travel-dates-field': {
      flex: ['100%', '1', '1 1 340px'],
    },
  })
);

const FlightPartitions: React.FC<IFlightPartitions> = ({
  renderDepartureAirportField,
  renderArrivalAirportField,
  withCustomLabels,
}) => {
  const [t] = useTranslation('forms');
  const { value: flightPartitions } = useFlightPartitions();
  const [activeType] = useField<FlightType | null>('flightType');
  // NOTE: If it's a Return or OneWay flight type we should render only first partition
  const partitionsToRender =
    activeType === FlightType.OpenJaw
      ? flightPartitions
      : flightPartitions?.slice(0, 1);
  const shouldShowPartitionHeadings = partitionsToRender
    ? partitionsToRender.length >= 2
    : false;

  return (
    <>
      {partitionsToRender &&
        partitionsToRender.map(
          (partition, index): React.ReactNode => (
            <FlightPartition key={index}>
              {shouldShowPartitionHeadings && (
                <h4 className="partition-title">
                  {`${t('flightSegment.heading')} ${index + 1}`}
                </h4>
              )}
              <FlightPartitionsWrapper
                shouldShowPartitionHeadings={shouldShowPartitionHeadings}
              >
                <div className="airport-field">
                  {renderDepartureAirportField(t, index, withCustomLabels)}
                </div>
                <div className="airport-field">
                  {renderArrivalAirportField(t, index, withCustomLabels)}
                </div>
                <div className="flight-travel-dates-field">
                  <FlightTravelDatesField
                    partitionIndex={index}
                    customLabel={
                      withCustomLabels ? (
                        <CustomLabel htmlFor="travelDates">
                          {t('forms:flightTravelDates.label')}
                        </CustomLabel>
                      ) : null
                    }
                  />
                </div>
              </FlightPartitionsWrapper>
            </FlightPartition>
          )
        )}
    </>
  );
};

export default FlightPartitions;
