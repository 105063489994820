import type { QueryResult, QueryHookOptions } from '@apollo/client';
import noop from 'lodash/noop';
import { useEffect } from 'react';
import { useFormContext } from '@hotelplan/components.common.forms';
import {
  FlightSearchControlComponentInput,
  SrlFilterCriteriaInput,
  FilterItem,
  FlightType,
} from '@hotelplan/graphql.types';
import { useSearchState } from '@hotelplan/libs.search-state';
import { useFilterCountErrorsContext } from 'components/domain/filters/FilterCountErrorsContext';
import { mapFilterToCountsMapper } from 'components/domain/filters/Filters.mappers';
import type {
  TUseCounts,
  TFilterCountsLoader,
  IFilterCount,
  IFiltersFormState,
  TUseMaxPrice,
  TUseFlightStopoverDuration,
  TUseFlightAirlines,
} from 'components/domain/filters/Filters.types';
import {
  TUsePrepareValuesForCountsRequest,
  countsHooksFactory,
} from 'components/domain/filters/search-filter-input/blocks/FilterCounts';
import { mapFormStateToFlightSearchControlCriteriaInput } from 'components/domain/flight/Flight.mappers';
import type { IFlightSearchControlFormState } from 'components/domain/searchControl/SearchControl.types';
import { useGetFlightSrlDirectFlightArrivalTimeFilterCountsQuery } from 'graphql/flightSRL/GetFlightSRLDirectFlightArrivalTimeFilterCounts.generated';
import { useGetFlightSrlDirectFlightDepartureTimeFilterCountsQuery } from 'graphql/flightSRL/GetFlightSRLDirectFlightDepartureTimeFilterCounts.generated';
import { useGetFlightSrlFlightAirlinesFilterCountsQuery } from 'graphql/flightSRL/GetFlightSRLFlightAirlinesFilterCounts.generated';
import { useGetFlightSrlMaxPricePerPersonFilterCountsQuery } from 'graphql/flightSRL/GetFlightSRLMaxPricePerPersonFilterCounts.generated';
import { useGetFlightSrlReturnFlightArrivalTimeFilterCountsQuery } from 'graphql/flightSRL/GetFlightSRLReturnFlightArrivalTimeFilterCounts.generated';
import { useGetFlightSrlReturnFlightDepartureTimeFilterCountsQuery } from 'graphql/flightSRL/GetFlightSRLReturnFlightDepartureTimeFilterCounts.generated';
import { useGetFlightSrlStopoverDurationFilterCountsQuery } from 'graphql/flightSRL/GetFlightSRLStopoverDurationFilterCounts.generated';
import { useGetFlightSrlStopOversFilterCountsQuery } from 'graphql/flightSRL/GetFlightSRLStopOversFilterCounts.generated';
import { useGetFlightSrlTotalFilterCountsQuery } from 'graphql/flightSRL/GetFlightSRLTotalFilterCounts.generated';
import { mapFlightSrlFormFilterValuesToFlightSrlFilterCriteriaInput } from './FlightSRL.mappers';
import type { IFlightSRLState } from './FlightSRL.types';

const usePrepareValuesForCountsRequestFlightSRL: TUsePrepareValuesForCountsRequest<{
  searchControl: FlightSearchControlComponentInput;
  filters: SrlFilterCriteriaInput;
}> = () => {
  const { values: filters } = useFormContext<IFiltersFormState>();
  const {
    state: { searchControl },
  } = useSearchState<IFlightSRLState>();

  return {
    countsQueryVariables: {
      searchControl: mapFormStateToFlightSearchControlCriteriaInput(
        searchControl as IFlightSearchControlFormState
      ),
      filters: mapFlightSrlFormFilterValuesToFlightSrlFilterCriteriaInput(
        filters
      ),
    },
    prevChangedFilter: filters.prevChangedFilter,
  };
};

const getCountsQueryFactory = (
  countsQueryExecutor: (options?: QueryHookOptions<any, any>) => QueryResult,
  filterName: string
) => {
  return function useQuery(options?: QueryHookOptions<any, any>): QueryResult {
    const { withErrors, saveWithErrors } = useFilterCountErrorsContext();
    const { values: filters } = useFormContext<IFiltersFormState>();
    const {
      state: { searchControl },
    } = useSearchState<IFlightSRLState>();

    const isOneWay = searchControl?.flightType === FlightType.OneWay;

    // NOTE: It's a place to configure skip recalculation logic for Flight Filters.
    let skipRecalculation = options?.skip;

    // NOTE: We should not send recalculation request for Return time filters if it's a OneWay flight type.
    if (
      isOneWay &&
      ['returnFlightDepartureTime', 'returnFlightArrivalTime'].includes(
        filterName
      )
    ) {
      skipRecalculation = true;
    }

    // NOTE: We should recalculate flightStopoverDuration min and max borders if it was reset.
    if (
      filterName === 'flightStopOverDuration' &&
      filters.flightStopOverDuration === null
    ) {
      skipRecalculation = false;
    }

    return countsQueryExecutor({
      ...options,
      skip: options?.skip || withErrors || skipRecalculation,
      onError() {
        saveWithErrors(true);
      },
    });
  };
};

const countsHooksFactoryFlightSRL = countsHooksFactory(
  usePrepareValuesForCountsRequestFlightSRL
);

export const useFlightAirlinesCountsInternal = countsHooksFactoryFlightSRL(
  getCountsQueryFactory(
    useGetFlightSrlFlightAirlinesFilterCountsQuery,
    'flightAirlines'
  ),
  data => data?.flightSrl.filters.flightAirlines,
  'flightAirlines'
);

export const useFlightAirlinesCounts: TUseFlightAirlines = options => {
  const { counts, loading } = useFlightAirlinesCountsInternal(options);
  const { state, setState } = useSearchState<IFlightSRLState>();

  // HMWEBDEV-2503 clear removed airlines from filters
  useEffect(
    function filterRemovedAirlines() {
      const currentAirlines = state.filters.flightAirlines;
      if (!currentAirlines || !currentAirlines.length) return;
      if (!loading && counts) {
        const loadedAirlines = (counts?.options as FilterItem[]).map(o => o.id);
        const filteredAirlines = currentAirlines.filter(a =>
          loadedAirlines.includes(a)
        );
        if (filteredAirlines.length !== currentAirlines.length) {
          setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              flightAirlines: filteredAirlines,
            },
          }));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, counts]
  );

  return {
    options: counts?.options.map((item: FilterItem) => ({
      id: item.id,
      caption: item.caption,
      count: item.productCount,
    })),
    loading,
  };
};

export const useFlightStopoverCounts = countsHooksFactoryFlightSRL(
  getCountsQueryFactory(
    useGetFlightSrlStopOversFilterCountsQuery,
    'flightStopOver'
  ),
  data => mapFilterToCountsMapper(data?.flightSrl.filters.stopOvers),
  'flightStopOver'
);

const useMaxPricePerPersonCountsInternal = countsHooksFactoryFlightSRL(
  getCountsQueryFactory(
    useGetFlightSrlMaxPricePerPersonFilterCountsQuery,
    'maxPricePerPerson'
  ),
  data => {
    return data?.flightSrl.content.flights.cheapestPrice
      ? parseInt(data.flightSrl.content.flights.cheapestPrice.amount)
      : undefined;
  },
  'maxPricePerPerson'
);

export const useMaxPricePerPersonCounts: TUseMaxPrice = props => {
  const { loading, counts } = useMaxPricePerPersonCountsInternal(props);
  return {
    minPrice: counts,
    loading,
  };
};

const useFlightStopoverDurationCountsInternal = countsHooksFactoryFlightSRL(
  getCountsQueryFactory(
    useGetFlightSrlStopoverDurationFilterCountsQuery,
    'flightStopOverDuration'
  ),
  data => data?.flightSrl.content.flights.stopOverDuration,
  'flightStopOverDuration'
);

export const useFlightStopoverDurationCounts: TUseFlightStopoverDuration = options => {
  const { loading, counts } = useFlightStopoverDurationCountsInternal(options);
  return {
    maxDuration: counts?.maxDuration,
    minDuration: counts?.minDuration,
    loading,
  };
};

export const useDirectFlightDepartureTimeCounts = countsHooksFactoryFlightSRL(
  getCountsQueryFactory(
    useGetFlightSrlDirectFlightDepartureTimeFilterCountsQuery,
    'directFlightDepartureTime'
  ),
  data =>
    mapFilterToCountsMapper(data?.flightSrl.filters.directFlightDepartureTime),
  'directFlightDepartureTime'
);

export const useDirectFlightArrivalTimeCounts = countsHooksFactoryFlightSRL(
  getCountsQueryFactory(
    useGetFlightSrlDirectFlightArrivalTimeFilterCountsQuery,
    'directFlightArrivalTime'
  ),
  data =>
    mapFilterToCountsMapper(data?.flightSrl.filters.directFlightArrivalTime),
  'directFlightArrivalTime'
);

export const useReturnFlightDepartureTimeCounts = countsHooksFactoryFlightSRL(
  getCountsQueryFactory(
    useGetFlightSrlReturnFlightDepartureTimeFilterCountsQuery,
    'returnFlightDepartureTime'
  ),
  data =>
    mapFilterToCountsMapper(data?.flightSrl.filters.returnFlightDepartureTime),
  'returnFlightDepartureTime'
);

export const useReturnFlightArrivalTimeCounts = countsHooksFactoryFlightSRL(
  getCountsQueryFactory(
    useGetFlightSrlReturnFlightArrivalTimeFilterCountsQuery,
    'returnFlightArrivalTime'
  ),
  data =>
    mapFilterToCountsMapper(data?.flightSrl.filters.returnFlightArrivalTime),
  'returnFlightArrivalTime'
);

export const useTotalResultsCounts = countsHooksFactoryFlightSRL(
  getCountsQueryFactory(
    useGetFlightSrlTotalFilterCountsQuery,
    'total' as const
  ),
  data => {
    const count = data?.flightSrl.content.flights.count;
    return count ? { count, id: '' } : { count: 0, id: '' };
  },
  'total' as const
);

const FlightSRLFilterCountsLoader: TFilterCountsLoader = {
  flightStopOver: useFlightStopoverCounts,
  flightStopOverDuration: useFlightStopoverDurationCounts,
  directFlightDepartureTime: useDirectFlightDepartureTimeCounts,
  directFlightArrivalTime: useDirectFlightArrivalTimeCounts,
  returnFlightDepartureTime: useReturnFlightDepartureTimeCounts,
  returnFlightArrivalTime: useReturnFlightArrivalTimeCounts,
  flightAirlines: useFlightAirlinesCounts,
  maxPricePerPerson: useMaxPricePerPersonCounts,
  total: useTotalResultsCounts,
  roomTypes: noop as TUseCounts<IFilterCount[]>,
  accommodationSize: noop as TUseCounts<IFilterCount[]>,
  boardTypes: noop as TUseCounts<IFilterCount[]>,
  departureAirports: noop as TUseCounts<IFilterCount[]>,
  beachFeatures: noop as TUseCounts<IFilterCount[]>,
  wellnessFeatures: noop as TUseCounts<IFilterCount[]>,
  tripAdvisorRating: noop as TUseCounts<IFilterCount[]>,
  sportFeatures: noop as TUseCounts<IFilterCount[]>,
  mainFeatures: noop as TUseCounts<IFilterCount[]>,
  popularFilters: noop as TUseCounts<IFilterCount[]>,
  hotelplanRating: noop as TUseCounts<IFilterCount[]>,
  hotelFeatures: noop as TUseCounts<IFilterCount[]>,
  childrenFeatures: noop as TUseCounts<IFilterCount[]>,
  cultureFeatures: noop as TUseCounts<IFilterCount[]>,
  distanceFeatures: noop as TUseCounts<IFilterCount[]>,
  environmentFeatures: noop as TUseCounts<IFilterCount[]>,
  maxFlightDuration: noop as TUseCounts<IFilterCount>,
};

export default FlightSRLFilterCountsLoader;
