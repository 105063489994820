import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { FlightSrlFilterValuesFragmentDoc } from './FlightSRLFilterValues.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetFlightSrlDefaultFilterValuesQueryVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetFlightSrlDefaultFilterValuesQuery = {
  __typename?: 'Query';
  flightSrl: {
    __typename?: 'FlightSrlContext';
    filters: {
      __typename?: 'FilterComponent';
      stopOvers: {
        __typename?: 'RadiobuttonFilterComponent';
        selected: string;
      };
      flightStopOverDuration: {
        __typename?: 'DoubleRangeSliderFilterComponent';
        caption: string;
        maxSelected: string;
        minSelected: string;
      };
      directFlightDepartureTime: {
        __typename?: 'RadiobuttonFilterComponent';
        selected: string;
      };
      directFlightArrivalTime: {
        __typename?: 'RadiobuttonFilterComponent';
        selected: string;
      };
      returnFlightDepartureTime: {
        __typename?: 'RadiobuttonFilterComponent';
        selected: string;
      };
      returnFlightArrivalTime: {
        __typename?: 'RadiobuttonFilterComponent';
        selected: string;
      };
      maxPrice: {
        __typename?: 'SliderFilterComponent';
        caption: string;
        selected: string;
      };
      flightAirlines: {
        __typename?: 'CheckboxFilterComponent';
        selected: Array<string>;
      };
    };
  };
};

export const GetFlightSrlDefaultFilterValuesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFlightSRLDefaultFilterValues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightSrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'encodedCriteria' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'FlightSRLFilterValues' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FlightSrlFilterValuesFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetFlightSrlDefaultFilterValuesQuery__
 *
 * To run a query within a React component, call `useGetFlightSrlDefaultFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlightSrlDefaultFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlightSrlDefaultFilterValuesQuery({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetFlightSrlDefaultFilterValuesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetFlightSrlDefaultFilterValuesQuery,
    GetFlightSrlDefaultFilterValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFlightSrlDefaultFilterValuesQuery,
    GetFlightSrlDefaultFilterValuesQueryVariables
  >(GetFlightSrlDefaultFilterValuesDocument, options);
}
export function useGetFlightSrlDefaultFilterValuesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFlightSrlDefaultFilterValuesQuery,
    GetFlightSrlDefaultFilterValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFlightSrlDefaultFilterValuesQuery,
    GetFlightSrlDefaultFilterValuesQueryVariables
  >(GetFlightSrlDefaultFilterValuesDocument, options);
}
export type GetFlightSrlDefaultFilterValuesQueryHookResult = ReturnType<
  typeof useGetFlightSrlDefaultFilterValuesQuery
>;
export type GetFlightSrlDefaultFilterValuesLazyQueryHookResult = ReturnType<
  typeof useGetFlightSrlDefaultFilterValuesLazyQuery
>;
export type GetFlightSrlDefaultFilterValuesQueryResult = Apollo.QueryResult<
  GetFlightSrlDefaultFilterValuesQuery,
  GetFlightSrlDefaultFilterValuesQueryVariables
>;
