import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetFlightSrlDirectFlightArrivalTimeFilterCountsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.FlightSrlFilterCriteriaInput>;
  searchControl: Types.FlightSearchControlComponentInput;
  urlParameter?: Types.InputMaybe<Types.Scalars['String']>;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetFlightSrlDirectFlightArrivalTimeFilterCountsQuery = {
  __typename?: 'Query';
  flightSrl: {
    __typename?: 'FlightSrlContext';
    filters: {
      __typename?: 'FilterComponent';
      directFlightArrivalTime: {
        __typename?: 'RadiobuttonFilterComponent';
        options: Array<{
          __typename?: 'FilterItem';
          id: string;
          productCount: number;
        }>;
      };
    };
  };
};

export const GetFlightSrlDirectFlightArrivalTimeFilterCountsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetFlightSRLDirectFlightArrivalTimeFilterCounts',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'FlightSrlFilterCriteriaInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchControl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'FlightSearchControlComponentInput',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'urlParameter' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightSrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'encodedCriteria' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'urlParameter' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchCriteria' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'filter' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filters' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'searchControl' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'searchControl' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pageNumber' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'directFlightArrivalTime',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'options' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'productCount',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;

/**
 * __useGetFlightSrlDirectFlightArrivalTimeFilterCountsQuery__
 *
 * To run a query within a React component, call `useGetFlightSrlDirectFlightArrivalTimeFilterCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlightSrlDirectFlightArrivalTimeFilterCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlightSrlDirectFlightArrivalTimeFilterCountsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      searchControl: // value for 'searchControl'
 *      urlParameter: // value for 'urlParameter'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetFlightSrlDirectFlightArrivalTimeFilterCountsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetFlightSrlDirectFlightArrivalTimeFilterCountsQuery,
    GetFlightSrlDirectFlightArrivalTimeFilterCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetFlightSrlDirectFlightArrivalTimeFilterCountsQuery,
    GetFlightSrlDirectFlightArrivalTimeFilterCountsQueryVariables
  >(GetFlightSrlDirectFlightArrivalTimeFilterCountsDocument, options);
}
export function useGetFlightSrlDirectFlightArrivalTimeFilterCountsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFlightSrlDirectFlightArrivalTimeFilterCountsQuery,
    GetFlightSrlDirectFlightArrivalTimeFilterCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetFlightSrlDirectFlightArrivalTimeFilterCountsQuery,
    GetFlightSrlDirectFlightArrivalTimeFilterCountsQueryVariables
  >(GetFlightSrlDirectFlightArrivalTimeFilterCountsDocument, options);
}
export type GetFlightSrlDirectFlightArrivalTimeFilterCountsQueryHookResult = ReturnType<
  typeof useGetFlightSrlDirectFlightArrivalTimeFilterCountsQuery
>;
export type GetFlightSrlDirectFlightArrivalTimeFilterCountsLazyQueryHookResult = ReturnType<
  typeof useGetFlightSrlDirectFlightArrivalTimeFilterCountsLazyQuery
>;
export type GetFlightSrlDirectFlightArrivalTimeFilterCountsQueryResult = Apollo.QueryResult<
  GetFlightSrlDirectFlightArrivalTimeFilterCountsQuery,
  GetFlightSrlDirectFlightArrivalTimeFilterCountsQueryVariables
>;
